import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_STATE = {
    notifUser: '',
};

const USERS_SLICE = createSlice({
    name: 'users',
    initialState: DEFAULT_STATE,
    reducers: {
        setNotifUser: (state, action) => {
            state.notifUser = action.payload;
        },
        resetUsers: () => DEFAULT_STATE
    },
});

export const getNotifUser = ({ users: state} : any | string) => state.notifUser;

export const {
    setNotifUser,
    resetUsers,
} = USERS_SLICE.actions;

export default USERS_SLICE.reducer;
