import { TrendVariant } from '../components/TrendArrow';

export interface Trend {
    trendType: TrendVariant,
    trendValue: number | string
}

export enum DeliveryMethods {
    SMS = 'sms',
    WHATSAPP = 'whatsapp',
    PRINT = 'print',
    EMAIL = 'email',
    NONE = 'none'
}

export enum Variant {
    SPECIAL = 'SPECIAL',
    SEARCH = 'SEARCH',
    SETTINGS = 'SETTINGS',
    /**
     * This is only used for the filter search input field.
     */
    FILTER = 'FILTER'
}
