/* eslint-disable arrow-body-style */
import { format, isSameDay, subDays } from 'date-fns';
import { groupBy } from 'lodash';
import moment from 'moment';

import { FunctionComponent, useEffect, useLayoutEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { NotificationSwitchIndicator, IndicatorVariant } from '../../../pages/Settings/SettingsSection/Notification';
import { User } from '../../../pages/Users';
import { removeAllNotificationsByUserId, updateNotificationById } from '../../../services/NotificationsService';
import { getUser } from '../../../store/authStore';
import { setNotifLocation } from '../../../store/locationsStore';
import { getCanDisturb, setCanDisturd } from '../../../store/notificationsStore';
import { setNotifUser } from '../../../store/usersStore';
import Switch from '../../Swtich';

export interface Notif {
    title: string,
    body: string,
    seen: boolean,
    user?: any,
    report?: string,
    created: string,
    notify_id: number,
    location?: string,
    _id: string,
    createdTime: string

    // type?: string, // user | location | report
    // activity?: string // id(user | location) | name(report)
}

type Props = {
    notifications: Notif[],
    reloadNotifs: () => void,
}

const Notifications : FunctionComponent <Props> = ({ notifications, reloadNotifs }) => {
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const disturbState = useSelector(getCanDisturb);

    const [userNotifiactions, setUserNotifications] = useState<Notif[]>([]);
    const [disturbFlag, setDisturbFlag] = useState<boolean>(disturbState);

    useEffect(() => {
        setDisturbFlag(disturbState);
    }, []);

    useEffect(() => {
        setUserNotifications(notifications);
    }, [notifications]);

    useEffect(() => {
        dispatch(setCanDisturd(disturbFlag));
    }, [disturbFlag]);

    const configNotifDate = (date: string) => {
        let updatedDate = date;
        const today = format(new Date(), 'MMM d, yyyy');
        const yesterday = format(subDays(new Date(), 1), 'MMM d, yyyy');
        if (isSameDay(new Date(date), new Date(today))) {
            updatedDate = 'Today';
        }
        if (isSameDay(new Date(date), new Date(yesterday))) {
            updatedDate = 'Yesterday';
        }
        return updatedDate;
    };

    function renderNotifications() {
        const groupedNotifs = groupBy(userNotifiactions, item => format(new Date(item.created || ''), 'MMM d, yyyy'));
    
        return Object.keys(groupedNotifs).map((groupKey, index: number) => {
            const notifDate = configNotifDate(groupKey);
            return (
                <NotifSection
                    date={notifDate}
                    handleClearNotifs={() => handleClearNotificationsForDate(groupKey)}
                    handleUpdateNotif={() => reloadNotifs()}
                    notifications={groupedNotifs[groupKey]}
                />
            );
        });
    }

    function handleDoNotDisturb() {
        setDisturbFlag(!disturbFlag);
    }

    function handleClearAllNotifications() {
        removeAllNotificationsByUserId(user?.user_id || '')
            .then(res => {
                console.log('Removed notifications');
                reloadNotifs();
            })
            .catch(err => console.log('Error removing notifs'));
    }

    function handleClearNotificationsForDate(date: string) {
        console.log('Clear notifs for: ', date);
    }

    return (
        <NotificationsContainer>
            <div className='notif-body'>
                <NotificationsHeader>
                    <p>Notifications</p>
                    <div className='do-not-disturb'>
                        <span className='disturb-text'>Do Not Disturb</span>
                        <NotificationSwitchIndicator
                            isOn={!disturbFlag}
                            onClick={() => handleDoNotDisturb()}
                            variant={IndicatorVariant.USER}
                        />
                    </div>

                </NotificationsHeader>
                { renderNotifications() }
            
                <div className='no-notifications'>
                    No Earlier Notifications
                </div>
            </div>
            <ClearNotifications onClick={() => handleClearAllNotifications()}>
                Clear Notifications
            </ClearNotifications>
        </NotificationsContainer>
    );
};

export default Notifications;

type SectionProps = {
    date: string,
    handleClearNotifs: () => void,
    handleUpdateNotif: () => void,
    notifications: Notif[]
}

const NotifSection : FunctionComponent <SectionProps> = ({ date, notifications, handleUpdateNotif, handleClearNotifs }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    function updateNotificationStatus(id: string) {
        updateNotificationById(id, { seen: true })
            .then(() => {
                console.log('Updated notif success:');
                handleUpdateNotif();
            })
            .catch(err => console.log('Updated notif status fail:', err));
    }

    function handleGoToPage(type: string, id: string) {
        /*
            if (type === 'location') {
                gotToLocation(id);
            }
            if (type === 'user') {
                gotToUser(id);
            }
            if (type === 'report') {
                gotToReport(id);
            }
        */
    }

    function gotToLocation(id: string) {
        dispatch(setNotifLocation(id));
        history.push('/locations');
    }

    function gotToUser(id: string) {
        dispatch(setNotifUser(id));
        history.push('/users');
    }

    function gotToReport(reportName: string) {
        history.push('/report');
    }

    function getNotifConfig(notif: { location?: string, user?: string, report?: string}) {
        let disabled = true;
        let type = 'location';
        let id = '';
        if (notif.location && notif.location !== '') {
            disabled = false;
            id = notif.location;
        }

        if (notif.user && typeof notif.user === 'string') {
            disabled = false;
            type = 'user';
            id = notif.user;
        }

        if (notif.report && notif.report !== '') {
            disabled = false;
            type = 'report';
            id = notif.report;
        }

        return { disabled, type, id };
    }
    return (
        <NotifSectionContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <p className='notif-section-header' >{date}</p>
                {/* <p className='notif-section-header notif-section-clear' onClick={() => handleClearNotifs()}>Clear</p> */}
            </div>

            <div className='notif-section-list'>
                {notifications.map((notif: Notif, index: number) => {
                    const { created, seen, body, title, location, user, createdTime } = notif;
                    const isDisabled = getNotifConfig(notif).disabled;
                    const {type} = getNotifConfig(notif);
                     
                    // h:mm a
                    return (
                        <NotifsListItem
                            key={`notif-item-${Math.random() + index}`}
                            isSeen={seen}
                            disabled={isDisabled}
                            onClick={() => {
                                if (!isDisabled) {
                                    updateNotificationStatus(notif._id);
                                    handleGoToPage(type, getNotifConfig(notif).id);
                                }
                            }}
                        >
                            <div className='notif-view-indicatior'/>
                            <NotifItemContent isSeen={seen}>
                                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <p className='notif-item-title'>{title}</p>
                                    <p className='notif-item-date'>{moment(new Date(createdTime)).local().format('hh:mm a')}</p>
                                </div>
                                <p className='notif-item-body'>{body}</p>
                            </NotifItemContent>
                        </NotifsListItem>
                    );
                })}
            </div>
        </NotifSectionContainer>
    );
};

const NotificationsContainer = styled.div`
    width: 20.25rem;
    height: calc(100vh - 5.875rem);

    border-radius: 0.5rem;

    display: flex;
    flex-direction: column;

    overflow: hidden;

    .notif-body { 
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-bottom: var(--space-20);

        overflow: auto;

        /* margin: 0 var(--space-20); */
        margin: 0;

        ::-webkit-scrollbar {
            display: none;
            -ms-overflow-style: none; 
            scrollbar-width: none;  
        }
    }

    .no-notifications {
        flex: 1;
        height: 100%;
        display: flex;
        margin-top: var(--space-48);
        /* align-items: center; */
        justify-content: center;

        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-12);
        line-height: 0.75rem;
        
        color: var(--color-gray-500);

    }
`;

const NotificationsHeader = styled.div`
    margin: var(--space-16) var(--space-20);

    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-12);
    line-height: 0.75rem;
   
    color: var(--color-white);

    p{ margin: 0;}

    .do-not-disturb {
        display: flex;
        align-items: center;

        color: var(--color-gray-600);

        .disturb-text { margin-right: var(--space-8); }
    }

`;

const ClearNotifications = styled.div`
    padding: var(--space-16);

    display: flex;
    align-items: center;
    justify-content: center;

    flex-shrink: 1;
    bottom: 0;

    background: var(--color-gray-900);
    cursor: pointer;

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-12);
    line-height: 0.75rem;

    text-align: center;
    color: var(--color-yellow-500);

    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

`;

const NotifSectionContainer = styled.div`
    padding-top: var(--space-18);
    padding-bottom: var(--space-32);
    border: 1px solid var(--color-gray-700);
    border-left: 0;
    border-right: 0;

    font-family: Inter;
    font-style: normal;
    font-size: var(--font-12);
    line-height: 0.75rem;

    .notif-section-header {
        margin: 0;
        font-weight: 600;
        color: var(--color-gray-400);

        padding-left: var(--space-20);
        padding-right: var(--space-20);
    }

    .notif-section-clear {
        color: var(--color-yellow-500);
        text-align: right;
        cursor: pointer;
    }

    .notif-section-list {
        margin-top: var(--space-24);
    }

`;

const NotifsListItem = styled.div<{isSeen?: boolean, disabled?: boolean}>`
    margin-bottom: var(--space-20);
    padding: var(--space-12) var(--space-20);
    display: flex;

    .notif-view-indicatior {
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 50%;
        margin-right: var(--space-12);

        background: var(--jmmb-yellow);
        box-shadow: 0px 0px 8px #FADE68;

        ${props => props.isSeen && css`
            background-color: transparent;
            box-shadow: none;
        `}
    }

    ${props => !props.disabled && css`
        :hover {
            background-color: var(--color-gray-900);
            cursor: pointer;
        }

    `}

    :last-of-type { 
        margin-bottom: 0;
    }
`;

const NotifItemContent = styled.div<{isSeen?: boolean}>`
    width: 100%;

    display: flex;
    flex-direction: column;

    color: var(--color-white);
    font-weight: normal;

    p { margin: 0; }

    .notif-item-title {
        ${props => !props.isSeen && css`
            font-weight: 600;
        `}
    }

    .notif-item-date{
        font-weight: 500;
        font-size: 0.625rem;
        
        color: var(--color-gray-400);
    }

    .notif-item-body {
        /* width: 100%; */
        margin-top: var(--space-8);
        white-space: normal;

        line-height: 1rem;
        color: var(--color-gray-400);

        ${props => !props.isSeen && css`
            color: var(--color-gray-200);
        `}
    }
`;
