import React, { CSSProperties, FunctionComponent, ReactNode, useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Variant } from '../../../types/enumTypes';
import Checkbox, { CheckboxType } from '../../Chekbox';
import InputField from '../../InputField';
import { RadioButton } from '../../Radio';
import Search from '../../Search';
import StyledTooltip from '../../StyledToolTip';

type Props = {
    icon?: ReactNode,
    title: string,
    isSelected?: boolean,
    disabled?: boolean,
    onSelectOption?: () => void,
    customStyles?: CSSProperties,
    classes?: string,
    titleStyles?: CSSProperties,
    index?: any,
    type?: string,
    isSearchable?: boolean,
    preventInitialFocus?:boolean
}

const MenuOption : FunctionComponent<Props> = ({preventInitialFocus, isSearchable, type, index, title, icon, isSelected, disabled, onSelectOption, customStyles, classes}) => {
    const selector:any = useRef(null);
    
    useEffect(() => {
        if (index === 0 && type === 'dropdown' && !isSearchable && !preventInitialFocus) {
            selector.current.focus();
        }
    }, []);
    const handleKeyDown = (event:any) => {
        if (type !== 'dropdown') return;
        event.preventDefault();
        const focusableElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const currentElementIndex = Array.prototype.indexOf.call(focusableElements, document.activeElement);
        // up
        if (event.keyCode === 38 && index !== 0) {
            const prevElement:any = focusableElements[currentElementIndex - 1];
            if (prevElement) {
                prevElement.focus();
            }
        }

        // down
        if (event.keyCode === 40) {
            const nextElement:any = focusableElements[currentElementIndex + 1];
            
            if (nextElement.className !== focusableElements[currentElementIndex].className) return;
            if (nextElement) {
                nextElement.focus();
            }
        }

        if (event.key === 'Enter') {
            if (!disabled && onSelectOption) {
                onSelectOption();
                const currentIndex = currentElementIndex;
                const currentElement:any = focusableElements[currentIndex];
                const nearestDropdown = currentElement.parentElement.parentElement.parentElement.parentElement.querySelector('[tabindex]:not([tabindex="-1"])');
                nearestDropdown.focus();
                nearestDropdown.click();
            }
        }

        if (event.key === 'Tab') {
            const currentIndex = currentElementIndex;
            const currentElement:any = focusableElements[currentIndex];

            const nearestDropdown = currentElement.parentElement.parentElement.parentElement.parentElement.querySelector('[tabindex]:not([tabindex="-1"])');
            nearestDropdown.focus();
            nearestDropdown.click();
        }
    };
    return (
        <OptionContainer
            ref={selector}
            tabIndex={0}
            onClick={() => (!disabled && onSelectOption) && onSelectOption()}
            onKeyDown={handleKeyDown}
            disabled={disabled}
            style={customStyles}
            className={classes}
        >
            {icon && <OptionIcon>{icon}</OptionIcon>}
            {title}
        </OptionContainer>
    );
};
const RadioMenuOption : FunctionComponent<Props> = ({title, icon, isSelected, titleStyles, onSelectOption}) => (
    <OptionContainer selected={isSelected} style={{ cursor: 'default', justifyContent: 'space-between'}}>
        <div style={{ display: 'flex', alignItems: 'center'}}>
            <RadioButton className="radio-button" selected={isSelected || false} onClick={() => onSelectOption && onSelectOption()}>
                <div className="inner-circle menu-item-radio"/>
            </RadioButton>
            <span style={{marginLeft: '0.75rem', ...titleStyles}}>{title}</span>
        </div>
        {icon && <OptionIcon style={{ marginRight: 0 }}>{icon}</OptionIcon>}
    </OptionContainer>
);

interface CheckboxMenuProps {
    title: string,
    isSelected?: boolean,
    onSelectOption?: () => void,
    customStyles?: CSSProperties,
    checkboxStyles?: CSSProperties,
    titleStyles?: CSSProperties
    showTooltip?: boolean,
    onClick?: any
}

const CheckboxMenuOption : FunctionComponent<CheckboxMenuProps> = ({title, isSelected = false, onSelectOption, customStyles, checkboxStyles, titleStyles, showTooltip, onClick}) => (
    <OptionContainer style={customStyles} type="checkbox">
        <Checkbox
            checked={isSelected}
            type={CheckboxType.MENU_ITEM}
            defaultStyles={{ borderColor: 'var(--color-gray-300)', ...checkboxStyles}}
            onClick={onSelectOption}
        />
        {showTooltip ? <StyledTooltip className='tooltip' /> : ''}
        <span onClick={onClick} style={{marginLeft: 'var(--space-12)', ...titleStyles}}>{title}</span>
    </OptionContainer>
);

const OptionGroupTitle : FunctionComponent<Props> = ({title, customStyles}) => (
    <GroupContainer style={{...customStyles}} >
        {title}
    </GroupContainer>
);

type GroupProps = {
    title: string,
    customTitleStyles?: CSSProperties,
    customStyles?: CSSProperties,
    searchable?: boolean,
    setKeepOpen?: any,
    onChangeSearchValue?: any,
    resetList?: any
}
const GroupOptions : FunctionComponent<GroupProps> = ({resetList, onChangeSearchValue, setKeepOpen = () => { /**/ }, searchable, title, customTitleStyles, customStyles, children}) => {
    const [searchValue, setSearchValue] = useState('');
    const handleSearch = (e: any) => {
        const { value } = e.currentTarget;
        setSearchValue(value);
        onChangeSearchValue(value);
    };
    const selector:any = useRef(null);
    useEffect(() => {
        if (searchable) {
            const nearestInput = selector.current.querySelector('input');
            nearestInput.focus();
        }
    }, []);

    useEffect(() => {
        function handleClickOutside(event:any) {
            if (selector.current && !selector.current.contains(event.target)) {
                const focusableElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
                const currentElementIndex = Array.prototype.indexOf.call(focusableElements, document.activeElement);
                const currentIndex = currentElementIndex;
                const currentElement:any = focusableElements[currentIndex];
                const nearestDropdown = currentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.querySelector('[tabindex]:not([tabindex="-1"])');
                if (nearestDropdown !== undefined) {
                    nearestDropdown.focus();
                    nearestDropdown.click();
                }
            }
        }
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [selector]);

    useEffect(() => {
        if (searchable) {
            resetList();
        }
    }, []);
    const [isSearchFocused, setIsSearchFocused] = useState(true);

    return (
        <StyledOptions style={customStyles}>
            <span className="title">
                <OptionGroupTitle title={title} customStyles={customTitleStyles}/>
                {searchable ? (
                    <SearchContainer ref={selector}>
                        <Search
                            setIsSearchFocused={setIsSearchFocused}
                            searchable={searchable}
                            value={searchValue}
                            inputStyles={{width: '24rem'}}
                            searchStyles={
                                {
                                    backgroundColor: isSearchFocused ? '#525b64' : 'transparent',
                                    borderStyle: 'solid',
                                    borderWidth: '1px',
                                    borderColor: isSearchFocused ? '#65717c' : '#697581',
                                    height: '30px',
                                    borderRadius: '4px',
                                    opacity: 0.7,
                                }
                            }
                            placeholder={'Search Agent'}
                            onChange={e => handleSearch(e)}
                        />
                    </SearchContainer>
                ) : null}
            </span>
            {children}
        </StyledOptions>
    );
};

const BlockButtonOption: FunctionComponent<Props> = ({ title, onSelectOption, customStyles }) => (
    <BlockContainer onClick={onSelectOption} style={{...customStyles}}>
        {title}
    </BlockContainer>
);

type DividerProps = {
    customStyles?: CSSProperties
}

const Divider : FunctionComponent<DividerProps> = ({customStyles = {} }) => (
    <StyledDivider style={customStyles}/>
);

export {MenuOption, RadioMenuOption, CheckboxMenuOption, GroupOptions, OptionGroupTitle, Divider, BlockButtonOption};

export const OptionContainer:any = styled.div<{selected?: boolean, type?: string, disabled?: boolean}>`
    /* height: 1.875rem; */
    width: auto;

    display: flex;
    flex-direction: row;
    align-items: center;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: var(--space-14);
    line-height: 0.875rem;

    color: var(--color-white);
    cursor: pointer;

    margin-bottom: 0.5rem;
    padding: var(--space-8) var(--space-16);

    :last-of-type{
        margin-bottom: 0;
    }

    &:hover {
        background: var(--color-gray-900);
    }

    ${props => props.disabled && css`
        cursor: default;
        color: var(--color-gray-500);
        &:hover {
            background: none;
        }
    `}

    .radio-button {
        ${props => props.selected && css`
            background: var(--jmmb-yellow);
            border: 1px solid var(--jmmb-yellow);
        `}
    }

    .menu-item-radio {
        ${props => props.selected && css`
            background: var(--color-gray-800);
        `}
    }

    ${props => props.type === 'checkbox' && css`
        cursor: default;
        &:hover {
            background: none;
        }
    `}

`;

export const OptionIcon = styled.div`
    height: 0.875rem;
    width: 0.875rem;

    margin-right: 0.75rem;
`;

const GroupContainer = styled.div`
    width: auto;
    background: var(--color-gray-900);
    border-radius: 4px;

    font-weight: 500;
    font-size: 0.625rem;
    line-height: 0.875rem;

    padding: var(--space-2) var(--space-8);
    margin: 0 var(--space-16);
`;

const SearchContainer = styled.div`
    width: 100%;
    border-radius: 4px;

    font-weight: 500;
    font-size: 0.625rem;
    line-height: 0.875rem;

    padding: 0px;
    margin: 0 var(--space-16);
    margin-top: 10px;
`;

const StyledOptions = styled.div`
    display: flex;
    flex-direction: column;

    .title {
        margin-bottom: 0.5rem;
    }
`;

const StyledDivider = styled.div`
    height: 1px;
    width: 100%;
    background: var(--color-gray-700);
`;

const BlockContainer = styled.div`
    width: 100%;
    height: 1.75rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--color-gray-700);
    border-radius: 0.25rem;

    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-12);
    line-height: 0.75rem;
    
    color: var(--color-white);

    cursor: pointer;

    :hover {
        background: var(--color-gray-900);
    }
`;
