import { useEffect, Suspense, useState } from 'react';
import { BrowserRouter, Switch, useParams, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getToken, getUser, isAuthenticated } from '../store/authStore';
import { setBearerToken } from '../services/axios';

import { appRoutes, backOfficeNonAdminRoutes } from './index';
import DefaultLayout from '../layouts/DefaultLayout';
import HorizontalLayout from '../layouts/HorizontalLayout';
import VerticalLayout from '../layouts/VerticalLayout';
import CustomerTabletLayout from '../layouts/CustomerTabletLayout';
import { getRoleById } from '../services/RolesService';

type AuthParams = {
    id: string;
  };

function getLayout(authenticated : boolean) {
    return authenticated && window.location.pathname !== '/restricted' ? VerticalLayout : DefaultLayout;
    // return CustomerTabletLayout;
}

export default function Routes() {
    // const {id} = useParams<AuthParams>();
    const authenticated = useSelector(isAuthenticated);
    const user = useSelector(getUser);
    // const isTellerAuthenticated = false;

    const token = useSelector(getToken);
    const Layout = getLayout(authenticated);

    const [routes, setRoutes] = useState(appRoutes);

    useEffect(() => {
        const runUpdate = async () => {
            await setBearerToken(token);
        };
        runUpdate();
    }, [token]);

    return (
        <BrowserRouter>
            <Layout>
                <Suspense fallback={<></>}>
                    <Switch>
                        {routes.map((route, index) => <route.route
                            key={index}
                            exact={route.exact}
                            path={route.path}
                            component={route.component}
                        />)
                        }
                    </Switch>
                </Suspense>
            </Layout>
        </BrowserRouter>
    );
}
