import axios from 'axios';
import { handleRawResponse, handleError, handleResponse } from '../utils';
import endpoints from '../endpoints';
import { axiosInstance } from '../axios';

export function getAllUserSettinngs(params?: any) {
    const url = endpoints.settings;
    return axiosInstance.get(url, { params })
        .then(handleResponse)
        .catch(handleError);
}

export function getSettingsByUserId(id: string) {
    const url = endpoints.settingsById(id);
    return axiosInstance.get(url)
        .then(handleResponse)
        .catch(handleError);
}

export function updateSettingsByUserId(id: string, data: any) {
    const url = endpoints.settingsById(id);
    return axiosInstance.put(url, data)
        .then(handleResponse)
        .catch(handleError);
}
