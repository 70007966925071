import { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { ReactComponent as ErrorWarning } from '../../../assets/svg/warning-error.svg';
import Button from '../../../components/Button';

const Error404 : FunctionComponent = () => {
    const history = useHistory();
     
    useEffect(() => {
        history.push('/auth/login');
    }, []);
    return (
        <ErrorContainer>
            <div className="container">
                <ErrorWarning />
                <p>Oops, something went wrong.</p>
            </div>
        </ErrorContainer>
    );
};

export default Error404;

const ErrorContainer = styled.div`
    width: 100%;
background-color: white;
    height: 100%;
.container {
    
    z-index: 10;
    position: relative;
    background-color: white;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30.5%;
    height: 75%;
    margin: 1em - 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    padding-top: 10%;
    
}
h2 {
    color: #353B40;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 24px;
}
p {
    position: relative;
    bottom: 0.75rem;
    color: var(--color-gray-600);
    font-size: 20px;

}
`;
const buttonStyle = {
    border: 'none',
    outline: 'none',
    background: '#B81F1E',
    padding: '0.2rem',
    color: 'white',
    gap: '10px',
    width: '100%',
    height: '44px',
    borderRadius: '4px',
    marginTop: '1rem ',
    fontSize: '20px'

};
