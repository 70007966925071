import axios from 'axios';
import { handleRawResponse, handleError, handleResponse } from '../utils';
import endpoints from '../endpoints';
import { axiosInstance } from '../axios';

export function getAgents(params?: any) {
    const url = endpoints.agents;
    return axiosInstance.get(url, { params })
        .then(handleResponse)
        .catch(handleError);
}

export function createAgent(data: any) {
    const url = endpoints.agents;
    return axiosInstance.post(url, data)
        .then(handleResponse)
        .catch(handleError);
}

export function getAgentById(id: string) {
    const url = endpoints.agentById(id);
    return axiosInstance.get(url)
        .then(handleResponse)
        .catch(handleError);
}

export function getAgentByCode(code: string) {
    const url = endpoints.agentByCode(code);
    return axiosInstance.get(url)
        .then(handleResponse)
        .catch(handleError);
}

export function updateAgentById(id: string, data: any) {
    const url = endpoints.agentById(id);
    return axiosInstance.put(url, data)
        .then(handleResponse)
        .catch(handleError);
}

export function removeAgentById(id: string) {
    const url = endpoints.agentById(id);
    return axiosInstance.delete(url)
        .then(handleResponse)
        .catch(handleError);
}
