export default {
    // ---- Audit ----
    audit: '/audit',
    // ---- OCR SERVICE ROUTES ----
    scanId: '/Scan',

    // ---- EMAIL SERVICE ROUTES ---
    email: '/email/doc',
    emailMessage: '/email',

    // ---- AUTH ROUTES ----
    login: '/auth/login',
    password: '/password',
    register: '/auth/register',
    refreshToken: '/auth/refreshToken',
    revokeToken: '/auth/revokeToken',
    
    // ---- DOCUMENT GENERATION ROUTES ----
    createContract: '/contract',
    viewDocument: '/contract/view',
    pingEndpoint: '/ping',

    // ---- DOCUMENT MANAGEMENT ROUTES ----
    documents: '/documents',
    documentById(id: string) {
        return `/documents/${id}`;
    },
    documentDataById(id: string) {
        return `/documents/${id}/data`;
    },

    // ---- USERS ROUTES ----
    users: '/users',
    userById(id: string) {
        return `/users/${id}`;
    },
    promoterUser(id: string) {
        return `/users/promote/${id}`;
    },
    relocateUser(id: string) {
        return `/users/relocate/${id}`;
    },
    resetUserPassword: '/users/reset',

    // ---- LOCATIONS ROUTES ----
    locations: '/locations',
    locationsNoTrend: '/locations/noTrend',
    locationById(id: string) {
        return `/locations/${id}`;
    },
    suspendLocation(id: string) {
        return `/locations/suspend/${id}`;
    },
    activateLocation(id: string) {
        return `/locations/activate/${id}`;
    },
    // ---- WORKSTATIONS ROUTES ----
    workstations: '/workstations',
    workstationById(id: string) {
        return `/workstations/${id}`;
    },
    workstationAssign(id: string) {
        return `/workstations/assign/${id}`;
    },

    // ---- ROLES ROUTES ----
    roles: '/roles',
    roleById(id: string) {
        return `/roles/${id}`;
    },

    // ---- CUSTOMERS ROUTES ----
    customers: '/customers',
    customerById(id: string) {
        return `/customers/${id}`;
    },

    // ---- TRANSACTIONS ROUTES ----
    transactions: '/transactions',
    transactionByRef(ref: string) {
        return `/transactions/${ref}`;
    },
    transactionById(id: string) {
        return `/transactions/id/${id}`;
    },
    flagTransactions: '/transactions/flag',
    unflagTransactions: '/transactions/unflag',

    // influx proxy
    influxById(id: string) {
        return `/influxProxy/document/${id}`;
    },

    influxData(id: string) {
        return `/influxProxy/document/${id}/data`;
    },

    // ---- AGENTS ROUTES ----
    agents: '/agents',
    agentByCode(code: string) {
        return `/agents/${code}`;
    },
    agentById(id: string) {
        return `/agents/${id}`;
    },

    // ---- SETTINGS ROUTES ----
    settings: '/settings',
    settingsById(id: string) {
        return `/settings/${id}`;
    },
    // ---- SMS ROUTE ----
    sms: '/sms/send',

    shortURL(id: string) {
        return `/shortUrl/${id}`;
    },

    // ---- REMITONE Transaction Details ----
    remitOneRef(ref: string) {
        return `/remitOne/transactionDetails/${ref}`;
    },

    // ---- Device Pairing ----
    pairing: '/pairing',
    pairingByPin(id: string) {
        return `/pairing/${id}`;
    },

    // ---- Upload files ----
    files: '/file',
    fileById(id: string): string {
        return `/file/${id}`;
    },

    // ---- REPORT ENDPOINTS ----
    transactionCount: '/transactions/count',
    transactionCountAll: '/transactions/countAll',
    reports: '/report',
    customReport: '/report/custom',
    getAllByUser(id: string) {
        return `/report/user/${id}`;
    },
    reportById(id:string) {
        return `/report/${id}`;
    },
    reportByUserId(id: string) {
        return `/report/user/${id}`;
    },
    
    // ---- NOTIFICATIONS ENDPOINTS ----
    notifications: '/notifications',
    notificationsById(id: string) {
        return `/notifications/${id}`;
    },
    notificationsByUserId(id: string) {
        return `/notifications/user/${id}`;
    },
    removeAllNotifByUser(id: string) {
        return `/notifications/all/${id}`;
    },
    removeDateNotifByUser(id: string) {
        return `/notifications/date/${id}`;
    },
    
    // ---- AGORA ENDPOINT ----
    agora: '/agora/rtctoken',

    // ---- CronPeriod Endpoint ----
    cronPeriod: '/cronPeriod'

};
