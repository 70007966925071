import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_STATE = {
    searchValue: '',
    searchPlaceholder: 'Search...'
};

const SEARCH_SLICE = createSlice({
    name: 'search',
    initialState: DEFAULT_STATE,
    reducers: {
        setSearch: (state, action) => {
            state.searchValue = action.payload;
        },
        setSearchPlaceholder: (state, action) => {
            state.searchPlaceholder = action.payload;
        },
        resestSearchValue: () => DEFAULT_STATE,
    },
});

export const getSearchValue = ({ search: state }: any | string) => state.searchValue;
export const getSearchPlaceholder = ({ search: state }: any | string) => state.searchPlaceholder;

export const {
    setSearch,
    setSearchPlaceholder,
    resestSearchValue,
} = SEARCH_SLICE.actions;

export default SEARCH_SLICE.reducer;
