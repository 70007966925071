import axios from 'axios';
import { handleRawResponse, handleError, handleResponse } from '../utils';
import endpoints from '../endpoints';
import { ocrAxiosInstance } from '../axios';

// OCR Calls

export function scanIDOcrEndpointCallPromise(formData : any) {
    const url = endpoints.scanId;
    const config = {headers: {'Content-Type': 'multipart/form-data'}};
    return ocrAxiosInstance.post(url, formData, config)
        .then(handleResponse)
        .catch(handleError);
}
