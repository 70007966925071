import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_STATE = {
    canDisturb: true,
    notifications: []
};

const NOTIFICATION_SLICE = createSlice({
    name: 'notification',
    initialState: DEFAULT_STATE,
    reducers: {
        setCanDisturd: (state, action) => {
            state.canDisturb = action.payload;
        },
        setNotifications: (state, action) => {
            state.notifications = action.payload;
        },
        resetNotification: () => DEFAULT_STATE,
    },
});

export const getCanDisturb = ({ notification: state } : any | boolean) => state.canDisturb;
export const getNotifications = ({ notification: state } : any) => state.notifications;

export const {
    setCanDisturd,
    setNotifications,
    resetNotification,
} = NOTIFICATION_SLICE.actions;

export default NOTIFICATION_SLICE.reducer;
