import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_STATE = {activeSession: false };

const TAB_SLICE = createSlice({
    name: 'tabs',
    initialState: DEFAULT_STATE,
    reducers: {
        setActiveSession: (state, action) => {
            state.activeSession = action.payload;
        },
    },
});

export const getActiveSession = ({ tabs: state } : any | number) => state.activeSession;

export const {setActiveSession} = TAB_SLICE.actions;

export default TAB_SLICE.reducer;
