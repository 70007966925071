import React, {FunctionComponent, CSSProperties, useState, ReactNode, useRef, useEffect} from 'react';
import styled, { css } from 'styled-components';
import { usePopper } from 'react-popper';
import { AnimatePresence, motion } from 'framer-motion';
import useOnClickOutside from '../../hooks/useOnClickOustide';

export enum Placement {
    auto = 'auto',
    autoStart = 'auto-start',
    autoEnd = 'auto-end',
    top = 'top',
    topStart = 'top-satr',
    topEnd = 'top-end',
    bottom = 'bottom',
    bottomStart = 'bottom-start',
    bottomEnd = 'bottom-end',
    right = 'right',
    rightStart = 'right-start',
    rightEnd = 'right-end',
    left = 'left',
    leftStart = 'left-start',
    leftEnd = 'left-end',
}

type Props = {
    highlightBorder?: boolean,
    setHighlightBorder?: (highlightBorder : boolean) => void,
    stayOpenOnHover?: boolean,
    getToggleOpenValue?: (value: boolean) => void,
    trigger: ReactNode,
    closeOnPress?: boolean,
    closePopover?: boolean,
    triggerOnHover?: boolean,
    popOverDisabled?: boolean,
    setClosePopover?: ((open: boolean) => void)| any,
    placement: Placement | any,
    customContainerStyles?: CSSProperties,
    customTriggerStyles?: CSSProperties,
    isArrow?: boolean,
}

const Popover : FunctionComponent<Props> = ({highlightBorder, setHighlightBorder, trigger, placement = 'bottom', closePopover, isArrow = false, closeOnPress = false, stayOpenOnHover, getToggleOpenValue, triggerOnHover, popOverDisabled, setClosePopover, customContainerStyles, customTriggerStyles, children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

    const popoverRef = useRef<HTMLDivElement>(null);
    const popoverChildRef = useRef<HTMLDivElement>(null);

    setHighlightBorder ?
        useOnClickOutside(popoverRef, () => { setIsOpen(false); setHighlightBorder(false); }) :
        useOnClickOutside(popoverRef, () => setIsOpen(false));
    
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [
            {
                name: 'arrow',
                enabled: isArrow,
                options: { element: arrowElement }
            }
        ],
        placement,
    });

    useEffect(() => {
        if (getToggleOpenValue) {
            getToggleOpenValue(isOpen);
        }
    }, [isOpen]);

    const toggleTrigger = () => {
        setIsOpen(!isOpen);
    };

    function handleOutsideHover() {
        if (triggerOnHover) {
            if (stayOpenOnHover && isOpen) {
                setIsOpen(false);
            }
        }
    }

    function onClickHandler() {
        if (!triggerOnHover) {
            if (!popOverDisabled) {
                toggleTrigger();
            }
        }
    }

    function onHoverEnter() {
        if (triggerOnHover) {
            if (!popOverDisabled) {
                setIsOpen(true);
            }
        }
    }

    function onHoverLeave() {
        if (triggerOnHover) {
            if (!popOverDisabled) {
                if (!stayOpenOnHover) setIsOpen(false);
            }
        }
    }

    useEffect(() => {
        // console.log('Setting popover open: ', closePopover);
        if (closePopover) {
            setIsOpen(false);
            setClosePopover(false);
        }
    }, [closePopover]);
  
    return (
        <PopoverWrapper ref={popoverRef}>
            <PopoverTrigger
                ref={setReferenceElement}
                onClick={() => onClickHandler()}
                onMouseEnter={onHoverEnter}
                onMouseLeave={onHoverLeave}
                style={customTriggerStyles}
            >
                {trigger && trigger}
            </PopoverTrigger>
            {isOpen &&
                (
                    <AnimatePresence>
                        <PopoverContainer
                            ref={setPopperElement}
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{duration: 0.5, ease: 'easeIn'}}
                            isArrow={isArrow}

                            style={{...styles.popper, ...customContainerStyles}}
                            {...attributes.popper}
                        >
                            <div
                                style={{ margin: 0, padding: 0}}
                                onClick={() => closeOnPress && setIsOpen(false)}
                                ref={popoverChildRef}
                                onMouseLeave={handleOutsideHover}
                            >
                                {children}
                            </div>
                            
                            <div ref={setArrowElement} style={styles.arrow} className="arrow"/>
                        </PopoverContainer>
                    </AnimatePresence>
                    
                )
            }
        </PopoverWrapper>
    );
};

export default Popover;

const PopoverWrapper = styled.div`
    /* position: relative; */
`;

const PopoverTrigger = styled.div`
    width: max-content;
    height: auto;
    position: relative;

    margin: 0;
    cursor: pointer;
`;

const PopoverContainer = styled(motion.div)<{isArrow: boolean}>`
    height: auto;
    width: auto;
    min-width: 13rem;
    padding: var(--space-16) 0;
    z-index: 2;
   
    
    white-space: nowrap;
    font-family: 'DM Sans';
    color: var(--color-white);
    background: var(--color-gray-800);
    border-radius: 0.5rem;
    text-align: left;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);

    ${props => props.isArrow && css`
        .arrow {
            position: absolute;
            width: 1.75rem;
            height: 1.75rem;
            z-index: -1;

            &:after {
                content: " ";
                position: absolute;
                z-index: -1;
                transform: rotate(45deg);
                width: 1.75rem;
                height: 1.75rem;
                border-radius: 0.2rem;
                background-color: var(--color-gray-800);
            }
        }

        &[data-popper-placement^='top'] > .arrow {
            bottom: -0.65rem;
        }

        &[data-popper-placement^='right'] > .arrow {
            left: -0.65rem;
        }

        &[data-popper-placement^='bottom'] > .arrow {
            top: -0.65rem;
        }

        &[data-popper-placement^='left'] > .arrow {
            right: -0.65rem;
        }
    `}
`;
