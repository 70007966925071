import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { PageTopBackground } from '../VerticalLayout';

const DefaultLayout : FunctionComponent = ({children}) => {
    return (
        <LayoutWrapper>
            <LayoutContainer>

                <PageTopBackground/>
                
                <PageContent>
                    {children}
                </PageContent>
                
            </LayoutContainer>
        </LayoutWrapper>
    );
};

export default DefaultLayout;

const LayoutWrapper = styled.div`
    height: 100vh;
    width: 100vw;
`;

const LayoutContainer = styled.div`
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;

    position: relative;
`;

const PageContent = styled.div`
    position: absolute;
    z-index: 1;
    top: 0;

    max-width: 1440px;

    height: 100%;
    width: 100%;
    
    display: flex;
    flex-direction: column;

        
`;
