import { FunctionComponent, useState, CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import InputField from '../InputField/index';
import Text from '../Text/index';

type RadioProps = {
    label?: string,
    selected?: boolean,
    onClick?: () => void
    disabled?: boolean,
    textStyles?: CSSProperties,
    radioButtonStyles?: CSSProperties,
    // name?: string,
    // onChange?: (event: any) => void,
    // value?: string,
    [x: string]: any
}

const Radio: FunctionComponent<RadioProps> = ({ label, onClick, selected = false, disabled, textStyles, radioButtonStyles }) => (

    <RadioContainer disabled={disabled}>
        <RadioButton style={radioButtonStyles} selected={selected} disabled={disabled} onClick={() => (!disabled && onClick) && onClick()}>
            <div className="inner-circle"/>
        </RadioButton>
        <p className="label" style={textStyles}>{label}</p>
    </RadioContainer>
);

export default Radio;

const RadioContainer = styled.div<{disabled?: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;

    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-12);
    line-height: 0.75rem;

    color: var(--color-gray-800);

    ${props => props.disabled && css`
      color: var(--color-gray-400);
    `}

    .label {
        margin: 0;
        margin-left: 0.75rem;
    }
`;

export const RadioButton = styled.div<{selected: boolean, disabled?: boolean}>`
    width: 1rem;
    height: 1rem;

    border-radius: 50%;
    border: 1px solid var(--color-gray-300);

    cursor: pointer;

    ${props => props.disabled && css`
      border-color: var(--color-gray-300);
    `}

    ${props => props.selected && css`
        background: var(--color-gray-800);
        display: flex;
        align-items: center;
        justify-content: center;
    `}

    .inner-circle {
        visibility: hidden;
        ${props => props.selected && css`
            visibility: visible;
            height: 0.375rem;
            width: 0.375rem;
            border-radius: 50%;
            background: var(--color-white);
        `}
    }
`;

const RadioWrapper = styled.div`
  display: inline-block;
`;

const Mark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: var(--color-white);
  border-radius: 50%;
  border: 1px solid var(--color-gray-300);

  &::after {
    content: "";
    border-radius: 50%;
    background-color: var(--color-white);
    position: absolute;
    transition: all 110ms;
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  &:checked + ${Mark} {
    background-color: var(--color-black);
    &::after {
      width: 6px;
      height: 6px;
      opacity: 1;
      left: 5px;
      top: 5px;
    }
  }
`;

const Label = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
`;

const labelTextStyle = {
    fontSize: 'var(--font-12)',
    color: 'var(--color-gray-800)',
    paddingTop: '1px'
};
