import { v4 as uuidv4 } from 'uuid';
import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from 'redux-persist';

import authReducer from './authStore';
import searchReducer from './searchStore';
import settingsReducer from './settingsStore';
import reportReducer from './reportStore';
import receiptReducer from './receiptsStore';
import locationsReducer from './locationsStore';
import notificationReducer from './notificationsStore';
import usersReducer from './usersStore';
import tabStore from './tabStore';

// const secretKey = process.env.REACT_APP_STORE_KEY || '';
const keyItem = localStorage.getItem('keyItem');
const secretKey = keyItem || uuidv4();

if (!keyItem) {
    localStorage.setItem('keyItem', uuidv4());
    window.location.reload();
}
const env = process.env.NODE_ENV;

export const config = {
    key: 'root',
    storage,
    blacklist: [],
    transforms: [
        encryptTransform({secretKey})
    ]
};

const rootReducer = combineReducers({
    auth: authReducer,
    search: searchReducer,
    settings: settingsReducer,
    report: reportReducer,
    receipt: receiptReducer,
    locations: locationsReducer,
    notification: notificationReducer,
    users: usersReducer,
    tabs: tabStore
});

const persistedReducer = persistReducer(config, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: {ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER], },
        immutableCheck: false
    }),
    devTools: env === 'development'
});

export { store };

export default store;
