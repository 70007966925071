import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Navigation from '../../navigation/NavigationComponent/Navigation';
import TopBar from '../../components/TopBar';
import Collapsible from '../../components/Collapsible/index';

const VerticalLayout: FunctionComponent = ({ children }) => {
    const history = useHistory();
    return (
        <LayoutWrapper>
            <LayoutContainer>

                <PageTopBackground/>
                <div className="page">
                    <div className="top-bar">
                        {/* TOPBAR */}
                        <TopBar pageTitle={children} />
                    </div>

                    <PageContainer>
                        <div className="nav">
                            <Navigation />
                        </div>
                        <div className="content">
                            {children}
                        </div>
                    </PageContainer>
                </div>

            </LayoutContainer>
        </LayoutWrapper>
    );
};

export default VerticalLayout;

const LayoutWrapper = styled.div`
    flex: 1;
    height: 100%;
    width: 100%;
`;

const LayoutContainer = styled.div`
    height: 100%;
    width: 100%;
    
    display: flex;
    justify-content: center;

    position: relative;

    .page {
        position: absolute;
        z-index: 1;
        top: 0;

        max-width: 1440px;

        height: 100%;
        width: 100%;
        
        display: flex;
        flex-direction: column;
    }

    .top-bar{
        height: 5rem !important;
        width: 100%;
        background: var(--easy-red);
        z-index: 2;
        position: fixed;
        /* height: 5rem !important;
        width: 100%;
        background: var(--easy-red); */

    }
`;

export const PageTopBackground = styled.div`
    width: 100%;
    height: 5rem;
    position: fixed;
    background: var(--easy-red);
`;

// const PageContainer = styled.div`
//     flex: 1;
//     height: 100%;
//     padding: 0 3rem;
//     height: 80%; // ! needed for content to scroll
//     background: var(--color-gray-100);
//     height: 80%;
//     display: flex;
//     flex-direction: row;
    
//     position: relative;

//     .nav{
//         width: 13rem;
//         height: 49.25rem;
//         position: absolute;
//         /* top: -1.75rem; */
//         padding-top: 1rem;
//         z-index: 2;
//         /* bottom: 2.25rem; */
//     }

//     .content {
//         flex: 1;
//         margin-left: 16rem;
//         overflow: scroll;
//         /* padding-top: 3rem; */
//         height: 100%;
//     }
// `;

const PageContainer = styled.div`
    flex: 1;
    height: 100%;
    padding: 1rem 3rem;
    /* height: 80%; // ! needed for content to scroll  */
    background: var(--color-gray-100);
    height: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 5rem;
    position: relative;

    .nav{
        width: 13rem;
        /* flex: 1; */
        /* height: 49.25rem; */
        /* position: absolute; */
        top: 0;
        /* padding-top: 1rem; */
        z-index: 2;
        position: fixed;
        /* bottom: 2.25rem; */
        height: 95vh;
    }

    .content {
        flex: 1;
        width: 100%;
        margin-left: 15rem;
        // overflow: auto;
        height: 100%;

        ::-webkit-scrollbar {
            width: 5px;               /* width of the entire scrollbar */
        }

        ::-webkit-scrollbar-track {
            background: transparent;        /* color of the tracking area */
        }

        ::-webkit-scrollbar-thumb {
            background-color: transparent;    /* color of the scroll thumb */
            border-radius: 20px;       /* roundness of the scroll thumb */
            border: 1px solid transparent;  /* creates padding around scroll thumb */
        }
    }


`;
