import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_STATE = {
    dashboardStatOption: 'Weekly',
    reportStatOption: 'Weekly',
    enableEmailNotifications: true,
    userProfile: {}
};

const SETTINGS_SLICE = createSlice({
    name: 'settings',
    initialState: DEFAULT_STATE,
    reducers: {
        setEnableEmailNotifications: (state, action) => {
            state.enableEmailNotifications = action.payload;
        },
        setDashboardStat: (state, action) => {
            state.dashboardStatOption = action.payload;
        },
        setReportStat: (state, action) => {
            state.reportStatOption = action.payload;
        },
        setUserProfile: (state, action) => {
            state.userProfile = action.payload;
        },
        resetSettings: () => DEFAULT_STATE
    },
});

export const getDashboardStat = ({ settings: state } : any) => state.dashboardStatOption;
export const getReportStat = ({ settings: state } : any) => state.reportStatOption;
export const getEnableEmailNotificationns = ({ settings: state} : any) => state.enableEmailNotifications;
export const getUserProfile = ({ settings: state } : any) => state.userProfile;

export const {
    setDashboardStat,
    setReportStat,
    setEnableEmailNotifications,
    setUserProfile,
    resetSettings,
} = SETTINGS_SLICE.actions;

export default SETTINGS_SLICE.reducer;
