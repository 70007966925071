import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';

const DEFAULT_STATE = {
    token: '',
    refreshToken: '',
    tokenExpire: new Date(),
    refreshExpire: new Date(),
    user: {},
    imei: '1000',
    kicked: false,
    forceLogout: false
};

const AUTH_SLICE = createSlice({
    name: 'auth',
    initialState: DEFAULT_STATE,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setRefreshToken: (state, action) => {
            state.refreshToken = action.payload;
        },
        setRefreshExpire: (state, action) => {
            state.refreshExpire = action.payload;
        },
        setTokenExpire: (state, action) => {
            state.tokenExpire = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setImei: (state, action) => {
            state.imei = action.payload;
        },
        setKicked: (state, action) => {
            state.kicked = action.payload;
        },
        logoutUser: () => {
            // add revoke token endpoint
            const urlsToDelete = localStorage.getItem('urlStore');
            // clear saved document urls
            if (urlsToDelete) {
                const item = urlsToDelete.split(',');
                item.map(res => {
                    URL.revokeObjectURL(res);
                });
            }
            localStorage.removeItem('urlStore');
            return DEFAULT_STATE;
        },
        forceLogout: (state, action) => {
            const urlsToDelete = localStorage.getItem('urlStore');
            // add revoke token endpoint
            // clear saved document urls
            if (urlsToDelete) {
                const item = urlsToDelete.split(',');
                item.map(res => {
                    URL.revokeObjectURL(res);
                });
            }

            localStorage.removeItem('urlStore');
            return action.payload;
        },
        setForceLogout: (state, action) => {
            state.forceLogout = action.payload;
        }
    },
});

export const isAuthenticated = ({auth: state} : any) => {
    // const { state } = auth;
    if ((Object?.keys(state?.user).length !== 0) && state?.token !== '') return true;
    return false;
};

export const getToken = ({ auth: state } : any | string) => state.token;
export const getRefreshToken = ({ auth: state } : any | string) => state.refreshToken;
export const getTokenExpire = ({ auth: state } : any | string) => state.tokenExpire;
export const getRefreshExpire = ({ auth: state } : any | string) => state.refreshExpire;
export const getUser = ({ auth: state } : any) => state.user;
export const getImei = ({ auth: state } : any) => state.imei;
export const getKicked = ({auth: state}: any) => state.kicked;
export const getForceLogout = ({ auth: state }: any) => state.forceLogout;

export const {
    setToken,
    setUser,
    setImei,
    logoutUser,
    forceLogout,
    setKicked,
    setForceLogout,
    setTokenExpire,
    setRefreshExpire,
    setRefreshToken
} = AUTH_SLICE.actions;

export default AUTH_SLICE.reducer;
