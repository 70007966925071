import React, { FunctionComponent, ReactNode } from 'react';
import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';

import { useDispatch } from 'react-redux';
import {ReactComponent as Overview} from '../../assets/svg/navigation/nav-overview-icon.svg';
import {ReactComponent as Report} from '../../assets/svg/navigation/nav-reports-icon.svg';
import {ReactComponent as Receipt} from '../../assets/svg/navigation/nav-receipt-icon.svg';
import {ReactComponent as Settings} from '../../assets/svg/navigation/nav-settings-icon.svg';
import {ReactComponent as Locations} from '../../assets/svg/navigation/nav-location-icon.svg';
import {ReactComponent as Users} from '../../assets/svg/navigation/nav-users-icon.svg';
import {ReactComponent as AuditIcon} from '../../assets/svg/navigation/nav-audit-icon.svg';

import { resestSearchValue } from '../../store/searchStore';

type Props = {
    icon?: any,
    selected: boolean,
    name: string,
    path: string,
    onClick: (name: string) => void
}

// eslint-disable-next-line arrow-body-style
const NavOption : FunctionComponent<Props> = ({name = 'Overview', path = '/', selected = false, onClick, icon}) => {
    const dispatch = useDispatch();
    const navIcon = (name: string, selected: boolean) => {
        const itemFilter = !selected ? {filter: 'invert(75%)'} : {};
        const iconColor = !selected ? 'var(--color-gray-800)' : 'var(--color-white)';
        switch (name) {
            case 'Overview':
                return <Overview fill={iconColor}/>;
            case 'Receipts':
                return <Receipt fill={iconColor}/>;
            case 'Locations':
                return <Locations fill={iconColor}/>;
            case 'Reports':
                return <Report fill={iconColor}/>;
            case 'Settings':
                return <Settings fill={iconColor}/>;
            case 'Users':
                return <Users fill={iconColor}/>;
            case 'Audit':
                return <AuditIcon fill={iconColor} style={itemFilter} />;
            default:
                return <Overview fill={iconColor}/>;
        }
    };
    const handleOnClick = () => {
        dispatch(resestSearchValue);
        onClick(path);
    };

    return (
        <OptionWrapper>
            <OptionContainer
                to={{
                    pathname: path,
                    state: { title: name}
                }}
                onClick={() => handleOnClick()}
                selected={selected}
            >
                <div className="icon">
                    {navIcon(name, selected)}
                </div>
                {name}
            </OptionContainer>
        </OptionWrapper>
    );
};

export default NavOption;

type StyleProps = {
    selected?: boolean
}

const OptionWrapper = styled.div`
    width: 100%;
    height: 4.5rem;

    margin-bottom: 1.5rem;
    :last-of-type{
        margin-bottom: 0;
    }
`;

const OptionContainer = styled(Link)<StyleProps>`
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;

    align-items: center;
    /* padding: 0 var(--space-16); */
    /* justify-content: center; */

    color: var(--color-gray-900);
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-16);
    line-height: 1rem;

    text-decoration: none;


    ${props => !props.selected && css`
        :hover {
            background-color: var(--color-gray-100);
            border-radius: 0.5rem;
        }
    `}
    

    ${props => props.selected && css`
        background: var(--jmmb-red);
        border-radius: 8px;
        color: var(--color-white);

    `}
    .icon {
        margin-right: var(--space-12);
        border-width: 0.375rem;
        border-style: solid;
        border-color: transparent;

        display: flex;
        justify-content: center;
        border-radius: 4px;
        margin-left: 1rem;
        
        ${props => props.selected && css`
            border-color: var(--dark-easy);
            background: var(--dark-easy);
        `}
    }
`;
