import { createSlice } from '@reduxjs/toolkit';
import { TemplateTypes } from '../pages/Reports/Configurations/ReportTemplate';
import { ConfigDataType, GroupingTypes } from '../pages/Reports/GroupingConfiguration/PreviewConfigDisplays';

const DEFAULT_STATE = {
    customTemplates: [],
    unsavedReport: false,
    reportName: '',
    reportDisabled: true,
    locationGroupLoading: false,
    reportLoading: false,
    reportStartDate: new Date(),
    reportEndDate: new Date(),
    reportFrequencyCount: 7,
    locationConfig: GroupingTypes.PARISH,
    dataConfig: ConfigDataType.GROUPS,
    reportLocations: [],
    template: {
        type: TemplateTypes.AGENTS,
        version: 'None'
    },
    hasReport: false
};

const REPORT_SLICE = createSlice({
    name: 'report',
    initialState: DEFAULT_STATE,
    reducers: {
        setCustomTemplates: (state, action) => {
            state.customTemplates = action.payload;
        },
        setUnsavedReport: (state, action) => {
            state.unsavedReport = action.payload;
        },
        setReportName: (state, action) => {
            state.reportName = action.payload;
        },
        setReportDisabled: (state, action) => {
            state.reportDisabled = action.payload;
        },
        setReportLoading: (state, action) => {
            state.reportLoading = action.payload;
        },
        setlocationGroupLoading: (state, action) => {
            state.locationGroupLoading = action.payload;
        },
        setReportStartDate: (state, action) => {
            state.reportStartDate = action.payload;
        },
        setReportEndDate: (state, action) => {
            state.reportEndDate = action.payload;
        },
        setReportFrequencyCount: (state, action) => {
            state.reportFrequencyCount = action.payload;
        },
        setLocationConfig: (state, action) => {
            state.locationConfig = action.payload;
        },
        setDataConfig: (state, action) => {
            state.dataConfig = action.payload;
        },
        setTemplate: (state, action) => {
            state.template = action.payload;
        },
        setReportLocations: (state, action) => {
            state.reportLocations = action.payload;
        },
        setHasReport: (state, action) => {
            state.hasReport = action.payload;
        },
        resetReport: () => DEFAULT_STATE,
        resetPage: (state) => {
            state.reportName = '';
            state.reportDisabled = true;
            state.reportLoading = false;
            state.reportStartDate = new Date();
            state.reportEndDate = new Date();
            state.reportFrequencyCount = 7;
            state.reportLocations = [];
            state.template = {
                type: TemplateTypes.AGENTS,
                version: 'None'
            };
            state.hasReport = false;
        }
    },
});

export const getReportName = ({ report: state } : any | string) => state.reportName;
// export const getReportDisabled = ({ report: state } : any | string) => state.reportDisabled;
export const getReportLoading = ({ report: state } : any | boolean) => state.reportLoading;
export const getLocationGroupLoading = ({ report: state } : any | boolean) => state.locationGroupLoading;
export const getReportStartDate = ({ report: state}: any | Date) => state.reportStartDate;
export const getReportEndDate = ({ report: state}: any | Date) => state.reportEndDate;
export const getReportFrequencyCount = ({ report: state } : any | number) => state.reportFrequencyCount;
export const getLocationConfig = ({ report: state}: any | GroupingTypes) => state.locationConfig;
export const getDataConfig = ({ report: state}: any | ConfigDataType) => state.dataConfig;
export const getReportTemplate = ({ report: state}: any | { type: TemplateTypes, version: string }) => state.template;
export const getReportLocations = ({ report: state}: any) => state.reportLocations;
export const getHasReportState = ({ report: state}: any | boolean) => state.hasReport;
export const getCustomTemplates = ({ report: state }: any) => state.customTemplates;
export const getUnsavedReport = ({ report: state }: any) => state.unsavedReport;

export const {
    setlocationGroupLoading,
    setCustomTemplates,
    setReportName,
    setReportDisabled,
    setReportLoading,
    setReportStartDate,
    setReportEndDate,
    setReportFrequencyCount,
    setLocationConfig,
    setDataConfig,
    setTemplate,
    setReportLocations,
    setHasReport,
    resetReport,
    resetPage,
    setUnsavedReport
} = REPORT_SLICE.actions;

export default REPORT_SLICE.reducer;
