import { FunctionComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

type Props = {
  className: string;
}

const StyledTooltip: FunctionComponent<Props> = props => <Tooltip className={props.className} arrowColor='#222426' />;

const Tooltip = styled(ReactTooltip)`
    &.tooltip {
        background-color: #222426;
        color: #fff;
        text-align: center;
        border-radius: 18px;
        padding: 2px 5px;
    }
`;

export default StyledTooltip;
