const OCR_BASE_API = process.env.REACT_APP_OCR_API;
const REACT_APP_API = process.env.REACT_APP_API;
const REACT_APP_DOCUMENT_MANAGEMENT_SERVICE = process.env.REACT_APP_DOCUMENT_MANAGEMENT_SERVICE;
const documentGenerationServiceEndpoint = process.env.REACT_APP_DOCUMENT_GENERATION_SERVICE;
const socketEndpointAPI = process.env.REACT_APP_SOCKET_API;

export {
    OCR_BASE_API,
    REACT_APP_API,
    documentGenerationServiceEndpoint,
    REACT_APP_DOCUMENT_MANAGEMENT_SERVICE,
    socketEndpointAPI
};
