import React, { FunctionComponent, useEffect, useState, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import {Link, useHistory, useLocation} from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';

// Assets
import { io } from 'socket.io-client';
import {ReactComponent as Overview} from '../../assets/svg/navigation/nav-overview-icon.svg';
import {ReactComponent as Report} from '../../assets/svg/navigation/nav-reports-icon.svg';
import {ReactComponent as Receipt} from '../../assets/svg/navigation/nav-receipt-icon.svg';
import {ReactComponent as Settings} from '../../assets/svg/navigation/nav-settings-icon.svg';
import {ReactComponent as Locations} from '../../assets/svg/navigation/nav-location-icon.svg';
import {ReactComponent as Users} from '../../assets/svg/navigation/nav-users-icon.svg';
import {ReactComponent as AuditIcon} from '../../assets/svg/navigation/nav-audit-icon.svg';

import {ReactComponent as Logo} from '../../assets/svg/company/nav-logo-icon.svg';

import { getUser, getToken, logoutUser, getRefreshToken } from '../../store/authStore';
import { getRoleById } from '../../services/RolesService';

import NavOption from './NavOption';
import { setUserProfile } from '../../store/settingsStore';
import { setNotifications } from '../../store/notificationsStore';
import { resetPage } from '../../store/reportStore';
import { getActiveSession, setActiveSession } from '../../store/tabStore';
// import { getReportDisabled } from '../../store/reportStore';

export const NAV_OPTIONS = [
    {
        name: 'Overview',
        icon: <Overview fill="var(--color-gray-800)"/>,
        navLink: '/'
    },
    {
        name: 'Receipts',
        icon: <Receipt fill="var(--color-gray-800)"/>,
        navLink: '/receipts'
    },
    {
        name: 'Locations',
        icon: <Locations fill="var(--color-gray-800)"/>,
        navLink: '/locations'
    },
    {
        name: 'Reports',
        icon: <Report fill="var(--color-gray-800)"/>,
        navLink: '/reports'
    },
    {
        name: 'Users',
        icon: <Users fill="var(--color-gray-800)"/>,
        navLink: '/users'
    },
    {
        name: 'Audit',
        icon: <AuditIcon fill="var(--color-gray-800)"/>,
        navLink: '/audit'
    },
    {
        name: 'Settings',
        icon: <Settings fill="var(--color-gray-800)"/>,
        navLink: '/settings'
    }
];

const Navigation : FunctionComponent = () => {
    const history = useHistory();
    const url = useLocation();
    const user = useSelector(getUser);
    const token = useSelector(getToken);
    
    const reportDisabled = useState(false); // useState(getReportDisabled);
    const { location } = history;
    const { pathname } = location;

    const [selected, setSelected] = useState<string>(pathname);
    const [navigationItems, setNavigationItems] = useState(NAV_OPTIONS);
    const _token = useSelector(getToken);
    const dispatch = useDispatch();

    // start here
    useEffect(() => {
        let socket:any = {};
        if (_token) {
            const socketEndpoint = process.env.REACT_APP_SOCKET_API || '';
            socket = io(socketEndpoint || '', {
                transports: ['polling'],
                extraHeaders: {Authorization: `Bearer ${_token}`},
                query: {type: 'aliveCheck'}
            });
        }
 
        return () => {
            socket.disconnect();
        };
    }, []);
    useEffect(() => {
        setSelected('/');
    }, []);
        
    useEffect(() => {
        if (location.pathname !== '/auth/login') setSelected(location.pathname);
        setSelected(window.location.pathname);
    }, [window.location.pathname, location.pathname, url]);
    useEffect(() => {
        if (location.pathname !== '/auth/login') {
            if (location.pathname === '/reports') {
                if (reportDisabled) {
                    setSelected(location.pathname);
                    setSelected(window.location.pathname);
                } else {
                    // Functionality to change open leave report prompt
                    console.log('Ask if want to leave before save');
                }
            }
        } else {
            setSelected(window.location.pathname);
        }
    }, [selected, window.location.pathname, location.pathname]);

    useEffect(() => {
        async function handleGetRole() {
            const role = await getRoleById(user?.role_id, {Authorization: `Bearer ${token}`});
            if ((role?.name || '') === 'branch_manager') {
                const navItems = NAV_OPTIONS.filter(item => (item.name !== 'Locations') && (item.name !== 'Users') && item.name !== 'Audit');
                setNavigationItems(navItems);
            }
            if ((role?.name || '') === 'director') {
                const navItems = NAV_OPTIONS.filter(item => (item.name !== 'Users') && item.name !== 'Audit');
                setNavigationItems(navItems);
            }
        }
        if (user) {
            handleGetRole();
        }
    }, [user]);

    const handleClickNavOption = (navName: string) => {
        setSelected(navName);
    };

    return (
        <NavigationWrapper>
            <NavigationContainer>
                <LabelContainer>
                    <Logo style={{transform: 'scale(1.2)'}} />
                </LabelContainer>

                <NavLinksContainer>
                    {
                        navigationItems.map((nav, index) => (
                            <NavOption
                                key={Math.random() + index}
                                name={nav.name}
                                path={nav.navLink}
                                onClick={handleClickNavOption}
                                selected={selected === nav.navLink}
                            />
                        ))
                    }
                </NavLinksContainer>
            </NavigationContainer>
        </NavigationWrapper>
    );
};

export default Navigation;

const NavigationWrapper = styled.div`
    width: 13rem;
    height: 100%;
`;

const NavigationContainer = styled.div`
    height: 100%;
    width: 100%;
    
    flex: 1;
    background: var(--color-white);
    box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    display: flex;
    flex-direction: column;
`;

const NavLinksContainer = styled.div`
    flex: 1;
    padding: var(--space-24);
    padding-bottom: 1.5rem;

    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 0.5rem;
    }
    ::-webkit-scrollbar-thumb {
        background-color: rgb(211, 217, 222, 1);
        border-radius: 0.5rem;
        margin-left: 3rem;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: rgb(211, 217, 222, 1);
    }
`;

const LabelContainer = styled.div`  
    flex-shrink: 1;

    display: flex;
    /* align-items: center; */
    justify-content: center;
    padding-bottom: var(--space-24);

    padding-top: var(--space-24);
    margin-top: 10px;

`;
