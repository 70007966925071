import axios from 'axios';
import { handleRawResponse, handleError, handleResponse } from '../utils';
import endpoints from '../endpoints';
import { axiosInstance } from '../axios';

export function getAllNotifications(params?: any) {
    const url = endpoints.notifications;
    return axiosInstance.get(url, { params })
        .then(handleResponse)
        .catch(handleError);
}

export function getNotificationsByUserId(id: string) {
    const url = endpoints.notificationsByUserId(id);
    return axiosInstance.get(url)
        .then(handleResponse)
        .catch(handleError);
}

export function removeAllNotificationsByUserId(id: string) {
    const url = endpoints.removeAllNotifByUser(id);
    return axiosInstance.delete(url)
        .then(handleResponse)
        .catch(handleError);
}

export function removeDateNotificationsByUserId(id: string) {
    const url = endpoints.removeDateNotifByUser(id);
    return axiosInstance.delete(url)
        .then(handleResponse)
        .catch(handleError);
}

export function updateNotificationById(id: string, data: any) {
    const url = endpoints.notificationsById(id);
    return axiosInstance.put(url, data)
        .then(handleResponse)
        .catch(handleError);
}
