import axios from 'axios';
import { handleRawResponse, handleError, handleResponse } from '../utils';
import endpoints from '../endpoints';
import { axiosInstance } from '../axios';

export function getRoles(params?: any) {
    const url = endpoints.roles;
    return axiosInstance.get(url, { params })
        .then(handleResponse)
        .catch(handleError);
}

export function createRole(data: any) {
    const url = endpoints.roles;
    return axiosInstance.post(url, data)
        .then(handleResponse)
        .catch(handleError);
}

export function getRoleById(id: string, headers: { Authorization: string}) {
    const url = endpoints.roleById(id);
    return axiosInstance.get(url, {headers: {...headers}})
        .then(handleResponse)
        .catch(handleError);
}

export function updateRoleById(id: string, data: any) {
    const url = endpoints.roleById(id);
    return axiosInstance.put(url, data)
        .then(handleResponse)
        .catch(handleError);
}

export function removeRoleById(id: string) {
    const url = endpoints.roleById(id);
    return axiosInstance.delete(url)
        .then(handleResponse)
        .catch(handleError);
}
