/* eslint-disable import/prefer-default-export */
/* eslint-disable object-curly-newline */
export const toasterOptions = {
    success: {
        style: {
            background: 'linear-gradient(0deg, #E6FFED, #E6FFED)',
            border: '2px solid #188F24',
            boxShadow: '0px 4px 10px rgba(120, 248, 160, 0.5), 0px 0px 0px 2px rgba(184, 249, 204, 0.3)',
            borderRadius: '12px',
            padding: '12px 16px',
            height: '58px'
        }
    },
    error: {
        style: {
            background: 'red'
        }
    },
    loading: {
        style: {
            background: 'blue'
        }
    },
    blank: { style: {} },
    custom: { style: {} }
};
