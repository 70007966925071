import React, { FunctionComponent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { GroupOptions, OptionGroupTitle, RadioMenuOption } from '../../../../components/Dropdown/MenuOptions';

import { ReactComponent as TrashIcon } from '../../../../assets/svg/trash-delete-icon.svg';
import { getAgents } from '../../../../services/AgentsService';
import { AgentInterface } from '../../../Locations/ManageAgentsMenu';
import { getAllReportByUser, getReports, removeReportById } from '../../../../services/ReportService';
import { getUser } from '../../../../store/authStore';

// import { getReportTemplate } from '../../../../store/reportStore';

export enum TemplateTypes {
    CUSTOM = 'Custom',
    LOCATIONS = 'All Locations',
    AGENTS = 'Agent',
    PARISH = 'Parish'
}
interface CustomReport {
    name: string,
    _id: string
}
export interface TemplateInterface {
    type: TemplateTypes,
    version: string,
    origin?: string
}
const DEFAULT_LOCATIONS_REPORTS = ['Regional'];
export const PARISHES = ['Kingston', 'St. Andrew', 'St. Catherine', 'St. Thomas', 'Portland', 'St. Mary',
    'St. Ann', 'Manchester', 'Trelawny', 'St. James', 'Hanover', 'St. Elizabeth', 'Westmoreland', 'Clarendon', 'Others'];

type Props = {
    customTemplates:any,
    reportShown: { type: TemplateTypes, version: string },
    handleUpdateTemplate: (data: TemplateInterface) => void,
    handleResetIcon: (state: boolean) => void,
    isResetTemplates: boolean,
    setCustom: any
}

const ReportTemplate : FunctionComponent <Props> = ({ customTemplates, handleUpdateTemplate, reportShown, handleResetIcon, isResetTemplates, setCustom }) => {
    const [agents, setAgents] = useState<AgentInterface[] | []>([]);
    const [customReports, setCustomReports] = useState<CustomReport[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const user = useSelector(getUser);

    useEffect(() => {
        if (isResetTemplates) {
            handleGetAgents();
            handleGetCustomPreports();
            handleResetIcon(false);
        }
    }, [isResetTemplates]);

    async function handleGetAgents() {
        const agents = await getAgents();
        const data : AgentInterface[] | [] = agents?.data || [];
        setAgents(data);
    }

    async function handleGetCustomPreports() {
        setIsLoading(true);
        getReports({ custom: true, limit: -1 })
            .then(res => {
                const { data } = res;
                setCustomReports(data);
            })
            .catch(err => {
                setCustomReports([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // const data : CustomReport[] | [] = reports?.data || [];
        // setCustomReports(data);
    }

    function handleSelectReportOption(type: TemplateTypes, name: string) {
        handleUpdateTemplate({
            type,
            version: name
        });
    }

    function handleRemoveCustomReport(id: string) {
        const customClone = [...customReports];
        const result = customClone.filter(item => item._id !== id);

        handleUpdateTemplate({
            type: TemplateTypes.AGENTS,
            version: 'None'
        });

        removeReportById(id)
            .then(() => {
                setCustom(result);
                setCustomReports(result);
            })
            .catch(err => {
                console.log('Could not delete custom report');
            });
    }

    useEffect(() => {
        if (customReports.length < 1) {
            const container:Array<CustomReport> = [];
            getAllReportByUser(user.user_id)
                .then(res => {
                    // map res
                    // push to container with just name and _id
                    // set the setCustomReports state
                    // save redux state
                    res.map((item:any) => {
                        container.push({
                            name: item.name,
                            _id: item._id
                        });
                    });
                })
                .then(result => {
                    setCustomReports(container);
                    setCustom(container);
                });
        }
    }, []);
    
    return (
        <TemplateDropdown>
            {/* ------------------- None ------------------- */}
            <RadioMenuOption
                isSelected={reportShown.version === 'None'}
                title={'None'}
                titleStyles={{
                    maxWidth: '10rem',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }}
                onSelectOption={() => handleSelectReportOption(TemplateTypes.AGENTS, 'None')}
            />

            {/* ------------------- Custom Reports ------------------- */}
            <GroupOptions
                title="Custom Reports"
                customStyles={groupStyle}
                customTitleStyles={{...titleStyle, textTransform: 'uppercase'}}
            >
                {customReports.length === 0 ? <div style={{margin: '0.75rem 0 0.625rem 1rem', fontSize: '14px'}}>No Custom Reports</div> : ''}
                {!isLoading && customReports.map((customReport: CustomReport, index: number) => (
                    <div key={`config-template-custom-${Math.random() + index}`} style={{ padding: 0 }}>
                        <RadioMenuOption
                            isSelected={reportShown.version === customReport.name}
                            title={customReport.name}
                            titleStyles={{
                                maxWidth: '10rem',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                            onSelectOption={() => handleSelectReportOption(TemplateTypes.CUSTOM, customReport.name)}
                            icon={
                                <TrashIcon
                                    stroke="var(--color-white)"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleRemoveCustomReport(customReport._id)}
                                />
                            }
                        />
                    </div>
                ))}
            </GroupOptions>

            {/* ------------------- All Locations Reports ------------------- */}
            <GroupOptions
                title="All Locations"
                customStyles={groupStyle}
                customTitleStyles={{...titleStyle, textTransform: 'uppercase'}}
            >
                {DEFAULT_LOCATIONS_REPORTS.map((locationTemp: string, index: number) => (
                    <div key={`config-template-location-${Math.random() + index}`} style={{ padding: 0 }}>
                        <RadioMenuOption
                            isSelected={reportShown.version === locationTemp}
                            title={'All Locations - Agents'}
                            onSelectOption={() => handleSelectReportOption(TemplateTypes.LOCATIONS, locationTemp)}
                        />
                    </div>
                ))}
            </GroupOptions>

            {/* ------------------- Parish Reports ------------------- */}
            <GroupOptions
                title="Parish Reports"
                customStyles={groupStyle}
                customTitleStyles={{...titleStyle, textTransform: 'uppercase'}}
            >
                {}
                {PARISHES.map((parish: string, index: number) => (
                    <div key={`config-template-parish-${Math.random() + index}`} style={{ padding: 0 }}>
                        <RadioMenuOption
                            isSelected={reportShown.version === parish}
                            title={`${parish} Locations`}
                            onSelectOption={() => handleSelectReportOption(TemplateTypes.PARISH, parish)}
                        />
                    </div>
                ))}
            </GroupOptions>
            
        </TemplateDropdown>
    );
};

export default ReportTemplate;

const titleStyle = {
    textTransform: 'uppercase',
    margin: '0 var(--space-8)',
    marginBottom: '0.5rem'
};

const groupStyle = {marginBottom: 'var(--space-16)'};

const TemplateDropdown = styled.div`
    /* padding: var(--space-16) var(--space-8); */
    padding-bottom: 0;

    display: flex;
    flex-direction: column;
`;

const NoCustomReports = styled.div`
    margin-left: 1rem;
`;
