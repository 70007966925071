import { FunctionComponent, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { getSettingsByUserId, updateSettingsByUserId } from '../../../../services/SettingsService';
import { getUser, setUser } from '../../../../store/authStore';
import { getEnableEmailNotificationns, setEnableEmailNotifications } from '../../../../store/settingsStore';
import Dropdown from '../../../../components/Dropdown';
import DropdownTrigger from '../../../../components/DropdownTrigger';
import { CheckboxMenuOption } from '../../../../components/Dropdown/MenuOptions';
import { getAgents } from '../../../../services/AgentsService';
import { getUserById } from '../../../../services/UsersService';

interface Notif {
    isOn: boolean,
    type: string,
    title: string
}
interface Notifs {
    _id: string,
    email_notifications: boolean,
    custom_reports: boolean,
    users: {
        'new_users': boolean,
        'status_changes': boolean,
        'deletions': boolean,
    },
    locations: {
        'new_locations': boolean,
        'status_changes': boolean,
        'deletions': boolean,
    },
    parish: { notify: boolean, name: string }[],
    agent: { notify: boolean, name: string }[],
}

const USER_NOTIFICATIONS = [
    {
        title: 'Notify me about new users',
        notifKey: 'new_users'
    },
    {
        title: 'Notify me about status changes',
        notifKey: 'status_changes'
    }
];

const LOCATION_NOTIFICATIONS = [
    {
        title: 'Notify me about new locations',
        notifKey: 'new_locations'
    }
    
];

const PARISH_NOTIFS = [
    {
        title: 'Kingston and St. Andrew',
        notifKey: 'kingston_standrew'
    },
    {
        title: 'Portland',
        notifKey: 'portland'
    },
    {
        title: 'St. Mary',
        notifKey: 'stmary'
    },
    {
        title: 'St. Thomas',
        notifKey: 'stthomas'
    },
    {
        title: 'St. Catherine',
        notifKey: 'stcatherine'
    },
    {
        title: 'St. Ann',
        notifKey: 'stann'
    },
    {
        title: 'Clarendon',
        notifKey: 'clarendon'
    },
    {
        title: 'Manchester',
        notifKey: 'manchester'
    },
    {
        title: 'Trelawny',
        notifKey: 'trelawny'
    },
    {
        title: 'St. James',
        notifKey: 'stjames'
    },
    {
        title: 'St. Elizabeth',
        notifKey: 'stelizabeth'
    },
    {
        title: 'Westmoreland',
        notifKey: 'westmoreland'
    },
    {
        title: 'Hanover',
        notifKey: 'hanover'
    },
];

const Notification: FunctionComponent = () => {
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const { user_id } = user;

    const emailFlag = useSelector(getEnableEmailNotificationns);

    const [notifications, setNotifications] = useState<Notifs | any>();

    const [parishNotifs, setParishNotifs] = useState<string[]>([]);
    const [agentNotifs, setAgentNotifs] = useState<string[]>([]);
    const [agents, setAgents] = useState<{ name: string }[]>([]);
    const [userRole, setUserRole] = useState<string>();
    const [reportNotif, setReportNotif] = useState<boolean>(false);

    useEffect(() => {
        if (user_id) {
            getNotificationsForUser();
            getAllAgents();
            getUserRole();
        }
    }, [user]);

    useEffect(() => {
        if (emailFlag) {
            setNotifications({ ...notifications, email_notifications: emailFlag });
        }
    }, [emailFlag]);

    function getNotificationsForUser() {
        getSettingsByUserId(user_id)
            .then(res => {
                Object.keys(res).map(key => {
                    if (key === 'email_notifications') {
                        dispatch(setEnableEmailNotifications(res[key]));
                    }
                });

                const parishes = (res?.parish || []).map((item: { name: string }) => item.name);
                const agentsRes = (res?.agent || []).map((item: { name: string }) => item.name);

                setNotifications({ ...res });
                setParishNotifs(parishes);
                setAgentNotifs(agentsRes);
            })
            .catch(err => console.log('Unable to get notifications for user:', err));
    }

    function getAllAgents() {
        getAgents({ limit: -1 })
            .then(res => setAgents(res?.data))
            .catch(err => setAgents([]));
    }

    function getUserRole() {
        if (user) {
            getUserById(user_id)
                .then(res => {
                    setUserRole(res?.role?.name || '');
                })
                .catch(err => setUserRole(''));
        }
    }

    function updatedNotification(updateData: any) {
        updateSettingsByUserId(user_id, { ...updateData })
            .then(res => {
                getNotificationsForUser();
            })
            .catch(err => console.log('Cannot update notifications: ', err));
    }
    // Functions that control group notifications

    function updateGroupAgentNotifications() {
        if (agentNotifs.length !== 0) {
            setAgentNotifs([]);
            updatedNotification({ agent: [] });
        } else {
            setAgentNotifs(
                agents.map(agent => agent.name)
            );
            updatedNotification({
                agent:
                    agents.map(item => ({ notify: true, name: item.name }))
            });
        }
    }

    function updateGroupParishNotifications() {
        // Updated the notifications of entire group
        if (parishNotifs.length !== 0) {
            setParishNotifs([]);
            updatedNotification({ parish: [] });
        } else {
            setParishNotifs(
                PARISH_NOTIFS.map(parish => parish.title)
            );
            updatedNotification({
                parish:
                    PARISH_NOTIFS.map(item => ({ notify: true, name: item.title }))
            });
        }
    }

    function updateGroupLocationNotifications() {
        const getAlllocations = notifications.locations;
        const getFalseNotif = Object.keys(getAlllocations).find(key => getAlllocations[key] === false);

        if (getFalseNotif) {
            updatedNotification({
                locations: {
                    new_locations: true,
                    status_changes: true,
                    deletions: true,
                }
            });
        } else {
            updatedNotification({
                locations: {
                    new_locations: false,
                    status_changes: false,
                    deletions: false,
                }
            });
        }
    }

    function updateGroupUserNotifications() {
        const getAllUsers = notifications.users;
        const getFalseNotif = Object.keys(getAllUsers).find(key => getAllUsers[key] === false);

        if (getFalseNotif) {
            updatedNotification({
                users: {
                    new_users: true,
                    status_changes: true,
                    deletions: true,
                }
            });
        } else {
            updatedNotification({
                users: {
                    new_users: false,
                    status_changes: false,
                    deletions: false,
                }
            });
        }
    }

    function updateGroupReportsNotifications() {
        const cond = ((notifications?.agent || []).length === 0) && ((notifications?.parish || []).length === 0) && (!notifications?.custom_reports || false);
        if (cond) {
            updateGroupAgentNotifications();
            updateGroupParishNotifications();
            updatedNotification({ custom_reports: true });
        } else {
            updatedNotification({
                custom_reports: false,
                parish: [],
                agent: []
            });
        }
    }

    function updateManagerGroupNotifications() {
        if (!notifications?.custom_reports || !notifications?.auto_reports) {
            updatedNotification({
                custom_reports: true,
                auto_reports: true
            });
        } else {
            updatedNotification({
                custom_reports: false,
                auto_reports: false
            });
        }
    }

    // Helper functions

    function selectParishOption(option: string) {
        let updatedParishes = parishNotifs;
        if ((parishNotifs)?.includes(option)) {
            updatedParishes = updatedParishes?.filter(parish => parish !== option);
        } else {
            updatedParishes = [...parishNotifs, option];
        }

        setParishNotifs(updatedParishes);

        updatedNotification({ parish: updatedParishes.map(parishItem => ({ notify: true, name: parishItem })) });
    }

    function selectAgentOption(option: string) {
        let updatedAgents = agentNotifs;
        if ((agentNotifs)?.includes(option)) {
            updatedAgents = updatedAgents?.filter(agent => agent !== option);
        } else {
            updatedAgents = [...agentNotifs, option];
        }

        setAgentNotifs(updatedAgents);
        updatedNotification({ agent: updatedAgents.map(agentItem => ({ notify: true, name: agentItem })) });
    }

    const checkGroupIsOn = (group: Record<string, boolean>) => {
        let isOn = true;

        const falseObject = Object.keys(group).find(key => group[key] === false);
        if (falseObject) {
            isOn = false;
        }
        return isOn;
    };

    const checkReportIsOn = () => {
        let isOn = true;
        const cond = ((notifications?.agent || []).length === 0) && ((notifications?.parish || []).length === 0) && (!notifications?.custom_reports || false);
        if (cond) isOn = false;

        return isOn;
    };

    useEffect(() => {
        if ((notifications?.agent || []).length > 0 || (notifications?.parish || []).length > 0) {
            setReportNotif(true);
            console.log('Report true');
        } else {
            setReportNotif(false);
            console.log('Report false');
        }
    }, [notifications]);

    useEffect(() => {
        if (notifications?.custom_reports && notifications?.auto_reports) {
            setReportNotif(true);
        }
    }, [notifications?.custom_reports, notifications?.auto_reports]);

    // TODO: Prevent flicker when updating a notification
    return (
        <NotificationContainer>
            <div className='group-notif'>
                <NotificationItem>
                    <p className="notif-title">Email Notifications</p>
                    <NotificationSwitchIndicator
                        isOn={notifications?.email_notifications || false}
                        onClick={() => updatedNotification({ email_notifications: !notifications?.email_notifications })}
                    />
                </NotificationItem>
            </div>
            {userRole !== 'branch_manager' &&
                <>
                    {/* --------------- REPORT NOTIFICATIONS ---------------- */}
                    <div className='group-notif'>
                        <NotificationItem>
                            <p className="notif-title">Report Notifications</p>
                            <NotificationSwitchIndicator
                                isOn={reportNotif}
                                onClick={() => updateGroupReportsNotifications()}
                            />
                        </NotificationItem>

                        <NotificationItem groupItem>
                            <p className="notif-title">Custom Reports</p>
                            <NotificationSwitchIndicator
                                isOn={notifications?.custom_reports || false}
                                onClick={() => updatedNotification({ custom_reports: !notifications?.custom_reports })}
                            />
                        </NotificationItem>

                        <NotificationItem dropdownItem>
                            <p className="notif-title">Agent Reports</p>
                            <NotificationSwitchIndicator
                                isOn={agentNotifs.length !== 0}
                                onClick={() => updateGroupAgentNotifications()}
                            />
                        </NotificationItem>

                        <div style={{ width: '34.125rem', margin: 0 }}>
                            <Dropdown
                                stayOpenOnPress
                                triggerStyles={{ width: '100%' }}
                                containerStyles={{ width: '100%' }}
                                trigger={
                                    <DropdownTrigger
                                        title={(agentNotifs)?.join(', ') || 'Nothing Selected'}
                                        customStyles={{ color: 'var(--color-gray-500)', }}
                                    />
                                }
                            >
                                <DropdownNotifications>
                                    {agents.map((agent: { name: string }, index: number) => (
                                        <CheckboxMenuOption
                                            title={agent.name}
                                            isSelected={agentNotifs?.includes(agent.name)}
                                            onSelectOption={() => selectAgentOption(agent.name)}
                                        />
                                    ))}
                                </DropdownNotifications>
                            </Dropdown>
                        </div>

                        <div style={{ marginTop: 'var(--space-20)', marginBottom: 'var(--space-24)', borderBottom: '1px dashed var(--color-gray-300)' }} />

                        <NotificationItem dropdownItem>
                            <p className="notif-title">Parish Reports</p>
                            <NotificationSwitchIndicator
                                isOn={parishNotifs.length !== 0}
                                onClick={() => updateGroupParishNotifications()}
                            />
                        </NotificationItem>

                        <div style={{ width: '34.125rem', margin: 0 }}>
                            <Dropdown
                                stayOpenOnPress
                                triggerStyles={{ width: '100%' }}
                                containerStyles={{ width: '100%' }}
                                trigger={
                                    <DropdownTrigger
                                        title={(parishNotifs)?.join(', ') || 'Nothing Selected'}
                                        customStyles={{ color: 'var(--color-gray-500)', }}
                                    />
                                }
                            >
                                <DropdownNotifications>
                                    {PARISH_NOTIFS.map((parishNotif: { title: string, notifKey: string }, index: number) => (
                                        <CheckboxMenuOption
                                            title={parishNotif.title}
                                            isSelected={parishNotifs?.includes(parishNotif.title)}
                                            onSelectOption={() => selectParishOption(parishNotif.title)}
                                        />
                                    ))}
                                </DropdownNotifications>
                            </Dropdown>
                        </div>
                    </div>

                    {userRole !== 'director' &&
                        /* --------------- USER NOTIFICATIONS ---------------- */
                        <div className='group-notif'>
                            <NotificationItem>
                                <p className="notif-title">User Notifications</p>
                                <NotificationSwitchIndicator
                                    isOn={checkGroupIsOn(notifications?.users || {})}
                                    onClick={() => updateGroupUserNotifications()}
                                />
                            </NotificationItem>

                            <>
                                {(notifications?.users) && USER_NOTIFICATIONS.map((userNotif: { title: string, notifKey: string }, index: number) => {
                                    const isOn = notifications?.users[userNotif?.notifKey || ''] || false;

                                    return (
                                        <NotificationItem groupItem>
                                            <p className="notif-title">{userNotif.title}</p>
                                            <NotificationSwitchIndicator
                                                isOn={isOn}
                                                onClick={() => updatedNotification({
                                                    users: {
                                                        ...notifications?.users,
                                                        [userNotif.notifKey]: !isOn
                                                    }
                                                })}
                                            />
                                        </NotificationItem>
                                    );
                                })}
                            </>
                        </div>
                    }

                    {/* --------------- LOCATIONS NOTIFICATIONS ---------------- */}
                    <div className='group-notif'>
                        <NotificationItem>
                            <p className="notif-title">Location Notifications</p>
                            <NotificationSwitchIndicator
                                isOn={checkGroupIsOn(notifications?.locations || {})}
                                onClick={() => updateGroupLocationNotifications()}
                            />
                        </NotificationItem>

                        <>
                            {(notifications?.locations) && LOCATION_NOTIFICATIONS.map((locationNotif: { title: string, notifKey: string }, index: number) => {
                                const isOn = notifications?.locations[locationNotif.notifKey] || false;
                                return (
                                    <NotificationItem groupItem>
                                        <p className="notif-title">{locationNotif.title}</p>
                                        <NotificationSwitchIndicator
                                            isOn={isOn}
                                            onClick={() => updatedNotification({
                                                locations: {
                                                    ...notifications?.locations,
                                                    [locationNotif.notifKey]: !isOn
                                                }
                                            })}
                                        />
                                    </NotificationItem>
                                );
                            })}
                        </>
                    </div>
                </>
            }
            {userRole === 'branch_manager' &&
                <>
                    <div className='group-notif'>
                        <NotificationItem>
                            <p className="notif-title" style={{ fontWeight: 100 }}>Notify me about new managers at my branch</p>
                            <NotificationSwitchIndicator
                                isOn={notifications?.users?.new_users || false}
                                onClick={() => updatedNotification({users: {new_users: !notifications?.users?.new_users}})}
                            />
                        </NotificationItem>
                    </div>
                    <div className='group-notif'>
                        <NotificationItem>
                            <p className="notif-title">Report Notifications</p>
                            <NotificationSwitchIndicator
                                isOn={reportNotif}
                                onClick={() => updateManagerGroupNotifications()}
                            />
                        </NotificationItem>

                        <NotificationItem groupItem>
                            <p className="notif-title">Auto-generated Reports</p>
                            <NotificationSwitchIndicator
                                isOn={notifications?.auto_reports || false}
                                onClick={() => updatedNotification({ auto_reports: !notifications?.auto_reports })}
                            />
                        </NotificationItem>

                        { userRole !== 'branch_manager' ? (
                            <NotificationItem dropdownItem>
                                <p className="notif-title">Custom Reports</p>
                                <NotificationSwitchIndicator
                                    isOn={notifications?.custom_reports || false}
                                    onClick={() => updatedNotification({ custom_reports: !notifications?.custom_reports })}
                                />
                            </NotificationItem>
                        ) : <div></div> }
                    </div>

                </>
            }

        </NotificationContainer>
    );
};

export default Notification;

export enum IndicatorVariant {
    USER = 'USER',
    SETTINGS = 'SETTINGS'
}

type IndicationProps = {
    isOn: boolean,
    variant?: IndicatorVariant,
    onClick: (data?: any) => any
};

export const NotificationSwitchIndicator: FunctionComponent<IndicationProps> = ({ isOn, onClick, variant = IndicatorVariant.SETTINGS }) => {
    const [isSwitchOn, setIsSwitchOn] = useState<boolean>(false);

    useEffect(() => {
        setIsSwitchOn(isOn);
    }, [isOn]);

    function handlSwitch() {
        setIsSwitchOn(!isSwitchOn);
        onClick();
    }

    return (
        <AnimatePresence>
            <NotificationSwitch
                isOn={isSwitchOn}
                variant={variant}
                initial={{ justifyContent: isSwitchOn ? 'flex-start' : 'flex-end' }}
                animate={{ justifyContent: isSwitchOn ? 'flex-end' : 'flex-start' }}
                transition={{ ease: 'easeIn', duration: 0.4 }}
                onClick={() => handlSwitch()}
            >
                <SwitchIndicator
                    isOn={isSwitchOn}
                    onClick={() => handlSwitch()}
                />

            </NotificationSwitch>
        </AnimatePresence>
    );
};

const NotificationContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;

    .group-notif {
        margin-bottom: 5.25rem;

        :last-of-type {
            margin-bottom: 0;
        }
    }
`;

const NotificationItem = styled.div<{ groupItem?: boolean, dropdownItem?: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: var(--font-14);
    line-height: 0.875rem;

    color: var(--color-gray-800);

    padding-bottom: var(--space-20);
    border-bottom: 1px solid var(--color-gray-300);

    margin-bottom: var(--space-24);

    ${props => props.groupItem && css`
        border-bottom-style: dashed;
        font-weight: normal;
    `}

    ${props => props.dropdownItem && css`
        border-bottom: none;
        font-weight: normal;
        padding-bottom: 0;
        margin-bottom: var(--space-14);
    `}
    :last-of-type {
        margin-bottom: 0;
    }

    .notif-title{
        margin: 0;
    }
`;

const DropdownNotifications = styled.div`
    display: flex;
    flex-direction: column;

    /* padding: 0 var(--space-16); */

    height: 14.625rem;
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar { display: none; }
    -ms-overflow-style: none;
    scrollbar-width: none;
`;
interface StyledProps {
    isOn: boolean,
    variant?: IndicatorVariant
}

const NotificationSwitch = styled(motion.div) <StyledProps>`
    height: 1.375rem;
    width: 2.75rem;
    cursor: pointer;

    display: flex;
    align-items: center;
    /* justify-content: flex-end; */

    border-radius: 11px;
    background: var(--color-gray-300);

    ${props => props.isOn && css`
        background: var(--jmmb-red);
    `}

    ${props => (props.variant === IndicatorVariant.USER && props.isOn) && css`
        background-color: var(--color-yellow-500);
    `}
    ${props => (props.variant === IndicatorVariant.USER && !props.isOn) && css`
        background-color: var(--color-gray-700);
    `}
`;

const SwitchIndicator = styled.div<StyledProps>`
    width: 1rem;
    height: 1rem;

    background: var(--color-white);
    border-radius: 9px;

    margin-left: 3px;
    margin-right: 3px;

    cursor: pointer;

    ${props => props.isOn && css`
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    `}
`;
