import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_STATE = {
    receiptLocationId: '',
    filterReceiptsByLocation: '',
};

const RECEIPT_SLICE = createSlice({
    name: 'receipt',
    initialState: DEFAULT_STATE,
    reducers: {
        setReceiptLocationId: (state, action) => {
            state.receiptLocationId = action.payload;
        },
        setFilterReceiptsByLocation: (state, action) => {
            state.filterReceiptsByLocation = action.payload;
        },
        resetReceiptValues: () => DEFAULT_STATE,
    },
});

export const getReceiptLocationId = ({ receipt: state } : any | string) => state.receiptLocationId;
export const getFilterReceiptsByLocation = ({ receipt: state } : any | string) => state.filterReceiptsByLocation;

export const {
    setReceiptLocationId,
    resetReceiptValues,
    setFilterReceiptsByLocation
} = RECEIPT_SLICE.actions;

export default RECEIPT_SLICE.reducer;
