import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import toast from 'react-hot-toast';

import { ReactComponent as ToastError } from '../../assets/svg/toast-error-icon.svg';
import { ReactComponent as ToastSuccess } from '../../assets/svg/toast-success-icon.svg';

export enum ToasterTypes{
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    EDITING = 'EDITING'
}

type Props = {
    type: ToasterTypes,
    msg?: string | any,
    id: string
}

const ToasterComponent : FunctionComponent <Props> = ({ type, msg, id }) => {
    function closeToast() {
        toast.dismiss(id);
    }
    const defaultMsg = type === ToasterTypes.SUCCESS ? 'Action completed successfully' : 'Could not complete action';
    return (
        <ToasterContainer type={type}>
            <div className="icon-container">
                {type === ToasterTypes.SUCCESS && <ToastSuccess/>}
                {type === ToasterTypes.ERROR && <ToastError/> }
                {type === ToasterTypes.EDITING && <ToastSuccess/>}
            </div>
            <span>{msg || defaultMsg}</span>
        </ToasterContainer>
    );
};

export default ToasterComponent;

const ToasterContainer = styled.div<{type: ToasterTypes}>`
    min-width: 18.875rem;
    padding: 0.75rem 1rem;

    display: flex;
    flex-direction: row;
    align-items: center;
   
    box-sizing: border-box;
    border-radius: 8px;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-16);
    line-height: 1rem;

    ${props => props.type === ToasterTypes.SUCCESS && css`
        background: var(--color-green-100);
        border: 1px solid var(--color-green-800);
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.12), 0px 4px 6px rgba(0, 0, 0, 0.07);

        color: var(--color-green-800);
    `}

    ${props => props.type === ToasterTypes.ERROR && css`
        background: var(--color-orange-100);
        border: 1px solid var(--color-orange-900);
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.12), 0px 4px 6px rgba(0, 0, 0, 0.07);

        color: var(--color-orange-900);
    `}

    ${props => props.type === ToasterTypes.EDITING && css`
        background: var(--color-gray-100);
        color: var(--color-gray-700);
        border: 1px solid var(--color-gray-300);
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.12), 0px 4px 6px rgba(0, 0, 0, 0.07);
    `}

    .icon-container {
        margin: 0;
        margin-right: var(--space-8);

        ${props => props.type === ToasterTypes.SUCCESS && css`
            margin-right: var(--space-18);
        `}

        ${props => props.type === ToasterTypes.ERROR && css`
            margin-right: var(--space-16);
        `}
    }

`;
