import axios from 'axios';
import { logoutUser, setToken } from '../store/authStore';
import { REACT_APP_API, OCR_BASE_API, documentGenerationServiceEndpoint, REACT_APP_DOCUMENT_MANAGEMENT_SERVICE } from './configValues';

const axiosInstance = axios.create({
    // withCredentials: true,
    baseURL: REACT_APP_API,
    timeout: 0, // No time limit
    timeoutErrorMessage: 'TIMEOUT: This request took longer than expected.'
    
});

const ocrAxiosInstance = axios.create({
    baseURL: OCR_BASE_API
});

const documentGenerationAxiosInstance = axios.create({
    baseURL: documentGenerationServiceEndpoint,
});

const documentManagementAxiosInstance = axios.create({
    baseURL: REACT_APP_DOCUMENT_MANAGEMENT_SERVICE
});

export const setBearerToken = (token : string) => {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export { axiosInstance, ocrAxiosInstance, documentGenerationAxiosInstance, documentManagementAxiosInstance };
