export enum ConfigDataType {
    GROUPS = 'GROUPS',
    MEMBERS = 'MEMBERS',
    GROUPS_MEMBERS = 'GROUPS_MEMBERS',
    GROUPS_THEN_MEMBERS = 'GROUPS_THEN_MEMBERS'
}

export enum GroupingTypes {
    PARISH = 'PARISH',
    AGENT = 'AGENT',
    PARISH_AGENT = 'PARISH_AGENT',
    AGENT_PARISH = 'AGENT_PARISH',
    NONE = 'NONE'
}
