import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

type TextProps = {
    textStyles?: CSSProperties,
    headings?: boolean,
    sigLabel?: boolean,
    [x: string]: any
}

const Text = ({ textStyles = {}, ...props }: TextProps) => (
    <TextWrapper
        style={{ ...textStyles }}
        {...props}
    />
);

export default Text;

type StyleProps = {
    headings?: boolean,
    sigLabel?: boolean
}

const TextWrapper = styled.p<StyleProps>`
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-16);
    line-height: var(--font-16);
    
    color: var(--color-gray-700);

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* margin: 0; */

    ${props => props.sigLabel && css`
        color: var(--color-gray-500);
        font-weight: 500;
        text-align: center;
    `}
`;
