import React from 'react';
import styled, { CSSProperties } from 'styled-components';
import InputField from '../InputField';

import { ReactComponent as SearchIcon } from '../../assets/svg/topbar/search-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/svg/small-x-icon.svg';
import { Variant } from '../../types/enumTypes';

type SearchProps = {
    placeholder?: string,
    value: string,
    onChange?: (event: any) => void,
    IconComponent?: any | null,
    inputStyles?: CSSProperties,
    searchStyles?: CSSProperties,
    hideLeadingIcon?: boolean,
    showTrailingIcon?: boolean,
    variant?: Variant,
    onCloseClick?: (event: any) => void
    [x: string]: any,
    searchable?: boolean,
    setIsSearchFocused?: any
}

const Search = ({setIsSearchFocused, searchable, placeholder, value, onChange, IconComponent, inputStyles, searchStyles, hideLeadingIcon, showTrailingIcon, variant, onCloseClick, ...props }: SearchProps) => {
    const defaultProps = props;
    const { type = 'text' } = defaultProps;
    return (
        <SearchContainer style={searchStyles}>

            {!hideLeadingIcon && <div className="topbar-search-icon">
                {IconComponent || <SearchIcon stroke="var(--color-white)"/> }
            </div>}
            
            <InputField
                onFocus={() => {
                    if (searchable) {
                        setIsSearchFocused(true);
                    }
                }}
                onBlur={() => {
                    if (searchable) {
                        setIsSearchFocused(false);
                    }
                }}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                customStyles={{...inputStyles}}
                variant={variant || Variant.SEARCH}
            />

            {(showTrailingIcon && value.length > 0) && <div className="topbar-search-icon click" onClick={onCloseClick}>
                {IconComponent || <CloseIcon stroke='#8D9AA5' />}
            </div>}

        </SearchContainer>

    );
};

export default Search;

const SearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;

    border: 1px solid var(--color-gray-400);

    background: var(--darker-easy);
    padding: 4px 16px;
    border: none;
    outline: none;
    border-radius: 18px;
    margin-right: 2rem;

    .topbar-search-icon {
        margin: 0;
        padding: 0;
        
        width: 1rem;
        height: 1rem;

        display: flex;
        align-items: center;
        justify-content: center;
        
    }

    .click {
        cursor: pointer;
    }

`;
