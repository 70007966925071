import { FunctionComponent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';

import Dropdown from '../Dropdown';
import { BlockButtonOption, Divider, MenuOption, OptionGroupTitle } from '../Dropdown/MenuOptions';
import Text from '../Text/index';
import { IndicatorVariant, NotificationSwitchIndicator } from '../../pages/Settings/SettingsSection/Notification';

import defaultImg from '../../assets/defaultUserPic.png';
import {ReactComponent as DropdownIcon} from '../../assets/svg/topbar/dropdown.svg';

import { getRoleById } from '../../services/RolesService';
import { forceLogout, getToken, getUser, logoutUser } from '../../store/authStore';
import { getUserProfile, getDashboardStat, getEnableEmailNotificationns, getReportStat, setDashboardStat, setEnableEmailNotifications, setReportStat, setUserProfile } from '../../store/settingsStore';
import { updateSettingsByUserId } from '../../services/SettingsService';
import { formatRole } from '../../helpers/formatters';
import { setNotifications } from '../../store/notificationsStore';
import { resetPage } from '../../store/reportStore';
import { revokeToken } from '../../services/AuthService';

type Props = {
    [x: string]: any
}

interface Role {
    name?: string,
    [x: string]: any,
}

export const DEFAULT_STAT_OPTIONS = [
    {
        title: 'Daily',
        value: 'Daily'
    },
    {
        title: 'Weekly',
        value: 'Weekly'
    },
    {
        title: 'Monthly',
        value: 'Monthly'
    },
    {
        title: 'Quarterly',
        value: 'Quarterly'
    },
    {
        title: 'Yearly',
        value: 'Yearly'
    }
];

const UserProfile: FunctionComponent<Props> = () => {
    const user = useSelector(getUser);
    const userProfile = useSelector(getUserProfile);
    const { user_id } = user;
    const token = useSelector(getToken);
    const dashboard = useSelector(getDashboardStat);
    const report = useSelector(getReportStat);
    const emailFlag = useSelector(getEnableEmailNotificationns);

    const history = useHistory();
    const dispatch = useDispatch();

    const [role, setRole] = useState<Role>({});

    const [dashboardStatOption, setDashboardStatOption] = useState<string>('weekly');
    const [reportStatOption, setReportStatOption] = useState<string>('weekly');
    const [emailNotifFlag, setEmailNotifFlag] = useState<boolean>(false);
    const [userDetails, setUserDetails] = useState({first_name: user?.first_name, last_name: user?.last_name});

    useEffect(() => {
        if (user) {
            getRole();
            setUserDetails({first_name: user?.first_name, last_name: user?.last_name});
        }
    }, [user]);

    useEffect(() => {
        if (Object.keys(userProfile).length > 0) {
            setUserDetails({first_name: userProfile?.first_name, last_name: userProfile?.last_name});
        }
    }, [userProfile]);

    useEffect(() => {
        setDashboardStatOption(dashboard);
        setReportStatOption(report);
        setEmailNotifFlag(emailFlag);
    }, [dashboard, report, emailFlag]);

    const getRole = () => {
        // WORK AROUND UNTIL BETTER SOLUTION FOUND
        getRoleById(user.role_id, {Authorization: `Bearer ${token}`})
            .then((res: any) => setRole(res))
            .catch((err: any) => {
                setRole({name: 'N/A'});
            });
    };

    const logout = () => {
        batch(() => {
            revokeToken();
            dispatch(logoutUser());
            dispatch(setUserProfile({}));
            dispatch(setNotifications([]));
            dispatch(resetPage());
        });
    };

    function handleGoToSettings() {
        history.push('/settings');
    }

    function handleDashboardStat(option: string) {
        updateSettingsByUserId(user_id, { dashboard_charts: option })
            .then((res: any) => {
                setDashboardStatOption(option);
                dispatch(setDashboardStat(option));
            })
            .catch((err: any) => console.log('Could not update the dashboard settings: ', err));
    }

    function handleReportStat(option: string) {
        updateSettingsByUserId(user_id, { report_generation: option })
            .then((_: any) => {
                setReportStatOption(option);
                dispatch(setReportStat(option));
            })
            .catch((err: any) => console.log('Could not update the dashboard settings: ', err));
    }

    function handleEmailNotifications(flag: boolean) {
        updateSettingsByUserId(user_id, { email_notifications: flag })
            .then((res: any) => {
                setEmailNotifFlag(flag);
                dispatch(setEnableEmailNotifications(flag));
            })
            .catch((err: any) => console.log('Could not update the email settings: ', err));
    }
    
    const userInitials = `${(userDetails?.first_name || '').charAt(0)}${(userDetails?.last_name || '').charAt(0)}`;

    const onIdle = () => {
        revokeToken();
        dispatch(forceLogout({
            token: '',
            user: {},
            imei: '1000',
            kicked: true
        }));
    };
    const pgTimeout = parseInt(process.env.REACT_APP_PAGE_TIMEOUT || '0', 10);
        
    const idleTimer = useIdleTimer({
        onIdle,
        timeout: pgTimeout !== 0 && pgTimeout ? pgTimeout : 60000,
        crossTab: true
    });

    return (
        <Dropdown
            containerStyles={{top: '3rem', zIndex: 3}}
            trigger={
                <ProfileWrapper>
                    <ProfileContainer>

                        <div className="user-details">
                            <TempUserImg>{userInitials}</TempUserImg>
                            
                            <div className='user-info'>
                                <Text style={userNameStyles}>{(userDetails?.first_name || '').charAt(0)}. {userDetails?.last_name}</Text>
                                {/* <Text style={userNameStyles}>{(user?.first_name || '').charAt(0)}. {user.last_name}</Text> */}
                                <Text style={userProfileStyles}>{formatRole(role?.name || '') }</Text>
                            </div>
                        </div>
                        <DropdownIcon stroke="var(--color-white)"/>
                    </ProfileContainer>
                </ProfileWrapper>
            }
        >
            <ProfileContent>
                <OptionGroupTitle
                    title="USER OPTIONS"
                    customStyles={{margin: 0, }}
                />
                <LoginContent>
                    <p className="logged-in">Logged in as</p>
                    <p className="logged-in-user truncate">{userDetails?.first_name} {userDetails?.last_name}</p>
                    <BlockButtonOption
                        title="Edit Profile"
                        onSelectOption={() => handleGoToSettings()}
                    />
                </LoginContent>

                <Divider/>

                <Email>
                    <span>Email Notifications</span>
                    <NotificationSwitchIndicator
                        isOn={emailFlag}
                        onClick={() => handleEmailNotifications(!emailFlag)}
                        variant={IndicatorVariant.USER}
                        
                    />
                </Email>

                <Divider/>

                <Stats style={{marginTop: '1.125rem'}}>
                    <p className="stat-title">Dashboard Charts</p>
                    <StatOptionsContainer>
                        {DEFAULT_STAT_OPTIONS.map((option: {title: string, value: string}, index: number) => (
                            <StatOption
                                key={`${Math.random() + index}`}
                                selected={dashboardStatOption === option.value}
                                onClick={() => handleDashboardStat(option.value)}
                            >
                                {option.title}
                            </StatOption>
                        ))}
                    </StatOptionsContainer>
                </Stats>

                <Stats style={{marginTop: '1.375rem', marginBottom: '1.125rem'}}>
                    <p className="stat-title">Auto Report Generation</p>
                    <StatOptionsContainer>
                        {DEFAULT_STAT_OPTIONS.map((option: {title: string, value: string}, index: number) => (
                            <StatOption
                                key={`${Math.random() + index}`}
                                selected={reportStatOption === option.value}
                                onClick={() => handleReportStat(option.value)}
                            >
                                {option.title}
                            </StatOption>
                        ))}
                    </StatOptionsContainer>
                </Stats>

                <Divider/>

                <BlockButtonOption
                    title="Log Out"
                    onSelectOption={() => logout()}
                    customStyles={{marginTop: '1.125rem'}}
                />
    
            </ProfileContent>
        </Dropdown>

    );
};

export default UserProfile;

const userNameStyles = {
    margin: '0',
    fontSize: 'var(--font-16)',
    fontWeight: '500',
    color: 'var(--color-white)',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
};

const userProfileStyles = {
    margin: '0',
    fontSize: 'var(--font-12)',
    color: 'var(--color-white)',
    opacity: '0.7',
    textTransform: 'capitalize'
};

const ProfileWrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const ProfileContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin-left: var(--space-32); */

    .user-details {
        display: flex;
        flex-direction: row;
        margin-right: var(--space-16);
        min-width: 0;
        /* width: 12rem; */
    }

    .user-info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 8.875rem;

        margin-left: var(--space-10);
        
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

const TempUserImg = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 1.25rem;

    height: var(--space-40);
    width: var(--space-40);

    background: var(--color-purple-200);

    font-family: Inter;
    color: var(--color-purple-800);
    font-weight: 600;
    text-align: center;
    font-size: var(--font-18);
    line-height: 1.25rem;
    text-transform: uppercase;
`;

const ProfileContent = styled.div`
    display: flex;
    flex-direction: column;

    width: 14.125rem;
    padding: 0 1rem;
`;

const LoginContent = styled.div`
    display: flex;
    flex-direction: column;

    font-family: Inter;
    font-style: normal;
    font-weight: normal;

    margin-top: var(--space-14);
    margin-bottom: var(--space-14);
   
    .logged-in{
        font-size: var(--font-12);
        line-height: 0.75rem;

        color: var(--color-gray-500);
        margin:0;
        margin-bottom: var(--space-4);

    }

    .logged-in-user {
        font-size: var(--font-14);
        line-height: 0.875rem;

        color: var(--color-white);
        margin:0;
        margin-bottom: 0.5rem;

    }
`;

const Email = styled.div`
    width: 100%;
    margin: var(--space-14) 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-12);
    line-height: 0.75rem;

    color: var(--color-white);
`;

const Stats = styled.div`
    display: flex;
    flex-direction: column;

    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-12);
    line-height: 0.75rem;

    color: var(--color-white);

    .stat-title {
        margin: 0;
        margin-bottom: var(--space-8);
    }

`;

const StatOptionsContainer = styled.div`
    height: 1.125rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const StatOption = styled.div<{selected: boolean}>`
    width: 3.25rem;
    height: 100%;

    display: flex;
    margin-right: 3px;
    align-items: center;
    justify-content: center;

    border: 1px solid var(--color-gray-200);
    box-sizing: border-box;
    border-radius: 0.25rem;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-10);
    line-height: 0.875rem;

    text-align: center;

    color: var(--color-gray-200);
    cursor: default;

    ${props => !props.selected && css`
        :hover {
            background: var(--color-gray-900);
        }
    `}

    ${props => props.selected && css`
        background: var(--jmmb-yellow);
        border: 1px solid var(--jmmb-yellow);

        font-weight: bold;
        color: var(--color-gray-800);
    `}

`;
