import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, {css} from 'styled-components';
import useOnClickOutside from '../../hooks/useOnClickOustide';
import { setlocationGroupLoading } from '../../store/reportStore';

type DropdownProps = {
    title?: string,
    children: React.ReactNode,
    trigger?: ReactNode,
    disabled?: boolean,
    isLocationList?: boolean,
    containerStyles?: CSSProperties,
    triggerStyles?: CSSProperties,
    stayOpenOnPress?: boolean,
    keepLocationListOpen?: boolean,
    setKeepLocationListOpen?: (keepLocationListOpen: boolean) => void,
    handleClickOutside?: () => void,
    handleToggleDropdown?: (openState: boolean) => void,
    keepOpen?: boolean,
    setKeepOpen?: any
}

const Dropdown = ({setKeepOpen = () => { /**/ }, keepOpen, title = '', children, trigger, disabled = false, isLocationList, containerStyles, triggerStyles, keepLocationListOpen = false, stayOpenOnPress = false, setKeepLocationListOpen, handleClickOutside, handleToggleDropdown}: DropdownProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    useOnClickOutside(dropdownRef, () => handleOnOutsideClick());
    useEffect(() => {
        if ((disabled && !isLocationList) || !keepLocationListOpen) {
            setIsOpen(false);
        }
    }, [disabled, keepLocationListOpen]);
    useEffect(() => {
        if (handleToggleDropdown) {
            handleToggleDropdown(isOpen);
        }
    }, [isOpen]);

    const toggleDropdown = () => {
        if (!disabled && !isLocationList) {
            setIsOpen(!isOpen);
            setKeepOpen(false);
        }
        if (!disabled && isLocationList) {
            setIsOpen(true);
            setKeepOpen(true);
            dispatch(setlocationGroupLoading(true));
        }
    };

    function handleOnOutsideClick() {
        if (!isLocationList) {
            setIsOpen(false);
            setKeepOpen(false);
            if (handleClickOutside) {
                handleClickOutside();
            }
        }
    }

    function handleClickToggle() {
        if (!disabled && keepOpen === undefined) {
            toggleDropdown();
            if (setKeepLocationListOpen !== undefined) {
                setKeepLocationListOpen(true);
            }
        }
    }

    const handleKeyDown = (event:any) => {
        if (event.key === 'Enter' && keepOpen === undefined) {
            handleClickToggle();
        }

        if (event.key === 'Enter' && keepOpen !== undefined) {
            handleFixed();
        }
    };

    const handleFixed = () => {
        if (keepOpen) {
            setKeepOpen(false);
        } else {
            setKeepOpen(true);
        }
    };

    const handleListPress = (event:any) => {
        const scrollGroupOptions = event.target.parentNode.className;
        if (scrollGroupOptions === 'scroll-group-options' && event.key === 'Enter') {
            setKeepOpen(false);
            handleClickToggle();
        }

        if (scrollGroupOptions === 'scroll-group-options' && event.key === undefined) {
            setKeepOpen(false);
            handleClickToggle();
        }
    };

    return (
        <DropdownWrapper ref={dropdownRef} style={{opacity: disabled ? 0.6 : 1.0}}>
            <DropdownTrigger onKeyDown={handleKeyDown} onMouseUp={() => handleFixed()} onClick={() => handleClickToggle()} style={triggerStyles}>
                { trigger || <span>{title}</span>}
            </DropdownTrigger>
            {
                isOpen || keepOpen ? (
                    <DropdownContent
                        onKeyDown={handleListPress}
                        isLocationList={isLocationList}
                        onClick={(e:any) => {
                            if (!stayOpenOnPress && keepOpen === undefined) {
                                return setIsOpen(false);
                            }
                            if (keepOpen !== undefined) {
                                return handleListPress(e);
                            }
                        }} style={containerStyles}
                    >
                        {children}
                    </DropdownContent>
                ) : null
            }
        </DropdownWrapper>
    );
};

export default Dropdown;

const DropdownWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;

`;

const DropdownTrigger = styled.div`
    display: flex;
    cursor: pointer;
`;

type StyleProps = {
    isLocationList?: boolean
}

const DropdownContent = styled.div<StyleProps>`

    height: auto;
    width: auto;
    min-width: 12.125rem;
    padding-top: var(--space-16);
   
    position: absolute;
    z-index: 4;
    right: 0;
    top: 2.25rem;

    transform-origin: top left;
    
    
    white-space: nowrap;
    font-family: 'DM Sans';
    color: var(--color-white);

    ${props => props.isLocationList === true && css`background: transparent;`}

  
    ${props => !props.isLocationList && css`
    background: var(--color-gray-800);
    padding-bottom: var(--space-16);`
}
    
    border-radius: 0.5rem;
    text-align: left;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
`;
