import React, { CSSProperties, FunctionComponent } from 'react';
import styled from 'styled-components';

// Assets
import { ReactComponent as Arrow } from '../../assets/svg/down-arrow.svg';
import { ReactComponent as LocationFilter } from '../../assets/svg/LocationFilter.svg';

type Props = {
    title: string,
    customStyles?: CSSProperties
    onClick?: any
    isLocationFilter?: boolean
}

const DropdownTrigger : FunctionComponent<Props> = ({title = '', customStyles = {}, onClick, isLocationFilter = false}) => (
    <TriggerContainer style={customStyles} onClick={onClick}>
        <p className={isLocationFilter ? 'location-filter' : 'title'}>{title}</p>
        {isLocationFilter ? <LocationFilter/> :
            <Arrow/>}
    </TriggerContainer>
);

export default DropdownTrigger;

const TriggerContainer = styled.div`
    height: 2rem;
    /* min-width: max-content; */
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: var(--space-8) var(--space-12);
    background: var(--color-white);

    border: 1px solid var(--color-gray-300);
    box-sizing: border-box;
    border-radius: 4px;

    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-14);
    line-height: 1rem;
    
    color: var(--color-gray-800);
    cursor: pointer;

    position: relative;

    .title {
        margin: 0;
        max-width: calc(100% - 36px);

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        margin-right: var(--space-10);
    }

    .location-filter {
        font-size: var(--font-12);
    }
`;
