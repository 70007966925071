import React, { FunctionComponent } from 'react';
import { format, parse } from 'date-fns';
import numeral from 'numeral';

export const formatNumber = (value: number) : string => numeral(value).format('0,0');

export const currencyFormatter = (value: string): string => parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

export const formatDate = (date: Date | number, formattingStr: string) : string => format(date, formattingStr);

export function formatRole(role: string) : string {
    return role === 'branch_manager' ? 'branch manager' : role;
}

export const formatNumberToDoubleDigits = (number: number) : string => (`0${number}`).slice(-2);

export const capitalizeString = (word: string) : string => word.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
export const capitalizeWords = (words: string) => words.split(' ').map((word) => { return (word[0] || '')?.toUpperCase() + (word?.substring(1) || '').toLowerCase(); }).join(' ');

// Data Size Formatting
export const formatBytes = (bytes: number, decimal = 2) : string => {
    if (bytes === 0) return '0B';

    const k = 1024;
    const dm = decimal < 0 ? 0 : decimal;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const pow = k ** i;
    return `${parseFloat((bytes / pow).toFixed(dm))} ${sizes[i]}`;
};

export const convertHTMLtoText = (html: any) : string => {
    const tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
};

export const getFirstLetters = (sentence: string) => {
    const firstLetters = sentence
        .split(' ')
        .map(word => word[0])
        .join('');

    return firstLetters;
};

export const getPercentage = (total: number, number: number) : number => {
    let res = Math.ceil((number / total) * 100);
    if (isNaN(res)) res = 0;
    return res;
};

export const formatPhoneNumber = (value: string) : string => value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

export const phoneNumberAutoFormat = (phoneNumber: string): string => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, '');
    if (number.length < 4) return number;
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, '$1-$2');
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3');
    return number.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
};

export const LocationIDAutoFormat = (LocationID: string): string => {
    const number = LocationID.trim().replace(/[^0-9]/g, '');
    if (number.length === 1) return number.replace(/(\d{1})/, '---$1');
    if (number.length === 2) return number.replace(/(\d{1})(\d{1})/, '--$1$2');
    if (number.length === 3) return number.replace(/(\d{1})(\d{1})(\d{1})/, '-$1$2$3');
    if (number.length === 5) return number.replace(/(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})/, '$1$2$3$4');
    return number;
};

export const getDeliveryColor = (id: string) => {
    switch (id) {
        case 'sms':
            return 'var(--color-purple-700)';
        case 'whatsapp':
            return 'var(--color-gray-800)';
        case 'email':
            return 'var(--jmmb-yellow)';
        case 'print':
            return 'var(--color-teal-400)';
        case 'none':
            return 'var(--color-gray-400)';
        default:
            return 'var(--color-gray-400)';
    }
};
