import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_STATE = {
    manageFilters: [],
    notifLocation: '',
};

const LOCATIONS_SLICE = createSlice({
    name: 'locations',
    initialState: DEFAULT_STATE,
    reducers: {
        setManageFilters: (state, action) => {
            state.manageFilters = action.payload;
        },
        setNotifLocation: (state, action) => {
            state.notifLocation = action.payload;
        },
        resetLocations: () => DEFAULT_STATE
    },
});

export const getManageFilters = ({ locations: state } : any) => state.manageFilters;
export const getNotifLocation = ({ locations: state} : any | string) => state.notifLocation;

export const {
    setManageFilters,
    setNotifLocation,
    resetLocations,
} = LOCATIONS_SLICE.actions;

export default LOCATIONS_SLICE.reducer;
