import React, { CSSProperties, FunctionComponent, useState } from 'react';
import styled, { css } from 'styled-components';

// Assets
import { ReactComponent as Tick } from '../../assets/svg/tick-icon.svg';

export enum CheckboxType {
    MENU_ITEM = 'MENU_ITEM'
}

type Props = {
    defaultStyles?: CSSProperties,
    selectedStyles?: CSSProperties,
    type?: CheckboxType,
    checked: boolean,
    onClick?: (data?: any) => void
}

const Checkbox: FunctionComponent<Props> = ({ checked, selectedStyles = {}, defaultStyles = {}, type, onClick }) => {
    const customStyles = checked ? selectedStyles : defaultStyles;
    const handleKeyDown = (event:any) => {
        if (event.key === 'Enter' && onClick) {
            onClick();
        }
    };
    return (
        <CheckboWrapper >
            <CheckboxContainer
                tabIndex={0}
                selected={checked}
                type={type}
                style={customStyles}
                onClick={onClick}
                onKeyDown={handleKeyDown}
            >
                {checked && <Tick stroke={type === CheckboxType.MENU_ITEM ? 'var(--color-gray-800)' : 'var(--color-white)'} style={{paddingBottom: '2px', width: '1rem'}}/>}
            </CheckboxContainer>
        </CheckboWrapper>
    );
};

export default Checkbox;

type StyleProps = {
    selected?: boolean,
    type?: CheckboxType
}

const CheckboWrapper = styled.div`
    height: 1rem;
    width: 1rem;
`;

const CheckboxContainer = styled.div<StyleProps>`
    height: 100%;
    width: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid var(--color-gray-300);
    border-radius: 2px;

    ${props => props.selected && css`
        background: var(--easy-red);
        border: 1px solid var(--easy-red);
    `}

    ${props => (props.type === CheckboxType.MENU_ITEM && props.selected) && css`
        background: var(--jmmb-yellow);
        border: 1px solid var(--jmmb-yellow);
    `}

    cursor: pointer;
`;
