/* eslint-disable import/no-unresolved */
/* eslint-disable node/no-missing-import */
/* eslint-disable import/extensions */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import { Toaster } from 'react-hot-toast';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { toasterOptions } from './const';
import ToasterComponent, { ToasterTypes } from './components/ToasterComponent';

import {store} from './store';

const persistedStore = persistStore(store);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistedStore}>
            <React.StrictMode>
                <App />
                <Toaster toastOptions={toasterOptions}>
                    {(toaster) => {
                        const { type = 'success', message = '', visible, id } = toaster;
                        const toasterStyles = toasterOptions[type]?.style || {};

                        let toasterType = type === 'success' ? ToasterTypes.SUCCESS : ToasterTypes.ERROR;
                        
                        if (id === 'editing') {
                            toasterType = ToasterTypes.EDITING;
                        }
                        
                        return (
                            <ToasterComponent
                                type={toasterType}
                                msg={message || ''}
                                id={id}
                            />
                        );
                    }}
                </Toaster>
            </React.StrictMode>
        </PersistGate>
        
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
