import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { handleRawResponse, handleError, handleResponse } from '../utils';
import endpoints from '../endpoints';
import { axiosInstance } from '../axios';

// ----------   AUTH CALLS  -----------
export function loginUser(data: any) {
    const url = endpoints.login;
    return axiosInstance.post(url, data)
        .then(handleResponse)
        .catch(handleError);
}

export function revokeToken() {
    const url = endpoints.revokeToken;
    return axiosInstance.post(url, {})
        .then(handleResponse)
        .catch(handleError);
}

export function refreshUserToken(data: any) {
    const url = endpoints.refreshToken;
    return axiosInstance.post(url, data)
        .then(handleResponse)
        .catch(handleError);
}

export function checkValidationValues(data: any) {
    const url = endpoints.password;
    return axiosInstance.post(url, data)
        .then(handleResponse)
        .catch(handleError);
}

export function resetPassword(data: any) {
    const url = endpoints.resetUserPassword;
    return axiosInstance.post(url, data)
        .then(handleResponse)
        .catch(handleError);
}

export function decodeToken(token: any) {
    return jwt_decode(token);
}
