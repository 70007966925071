/* eslint-disable arrow-body-style */
import React, { CSSProperties, useEffect, useRef } from 'react';
import styled, {css} from 'styled-components';

// Assets
import { ReactComponent as ErrorIcon } from '../../assets/svg/error-icon.svg';
import { Variant } from '../../types/enumTypes';

export interface Error {
    isError: boolean,
    errorMsg?: string | any
}

type InputProps = {
    type?: string,
    placeholder?: string,
    value?: string,
    recordTitle?: string,
    onChange?: (event: any) => void,
    customStyles?: CSSProperties,
    recordCustomStyles?: CSSProperties,
    error?: Error,
    variant?: Variant,
    [x: string]: any,
    maxLength?: number,
    index?: any,
    disabled?: boolean,
    autoComplete?: string,
    starred?: boolean
}

const InputField = ({autoComplete = 'off', disabled = false, index, type = 'text', placeholder, value, onChange, starred, recordTitle, error = {isError: false}, customStyles, recordCustomStyles, variant, maxLength, ...props}: InputProps) => {
    const selector:any = useRef(null);
    useEffect(() => {
        if (index === 0) {
            selector.current.focus();
        }
    }, []);
    return (
        <Record className='recordItem' variant={variant}>
            {recordTitle && <p className='record-title' style={recordCustomStyles}>{recordTitle} {starred ? <span style={{ color: 'red' }}> *</span> : null}</p>}
            <Input
                autoComplete={autoComplete}
                ref={selector}
                type={type}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                isError={error?.isError || false}
                style={{...customStyles, opacity: disabled ? 0.5 : 1}}
                variant={variant}
                maxLength={maxLength}
                {...props}
                disabled={disabled}
            />
            {error.isError &&
                <ErrorMessage>
                    <StyledIcon/>
                    <span>{error.errorMsg}</span>
                </ErrorMessage>
            }
        </Record>
       
    );
};

export default InputField;

type StyledProps = {
    isError: boolean,
    variant?: Variant
}

const Record = styled.div<{variant?: Variant}>`
    display: flex;
    flex-direction: column;

    position: relative;

    .record-title {
        margin-bottom: var(--space-8);

        ${props => props.variant === Variant.SETTINGS && css`
            margin-bottom: var(--space-12);

            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: var(--font-14);
            line-height: 0.875rem;

            color: var(--color-gray-500);

        `}
    }
`;

const Input = styled.input<StyledProps>`
   
    width: 100%;
    
    padding: var(--space-12) var(--space-16);
    border-radius: 0.25rem;

    background: var(--color-white);
    
    border: 1px solid var(--color-gray-400);
    box-sizing: border-box;

    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-16);
    line-height: 1rem;
  
    color: var(--color-gray-800);

    &:focus {
        /* outline: none; */
        border: 1px solid var(--color-purple-500);

    }

    ${props => props.isError && css`
        background: var(--color-orange-100);
        border: 1px solid var(--color-orange-900);

    `}

    ${props => props.variant === Variant.SPECIAL && css`
        height: 4rem;
        background: var(--color-white);
        box-shadow: 0px 47px 80px rgba(57, 57, 75, 0.05),
            0px 20px 32px rgba(57, 57, 75, 0.0303),
            0px 10px 18px rgba(57, 57, 75, 0.02),
            0px 6px 10px rgba(57, 57, 75, 0.02),
            0px 3px 6px rgba(57, 57, 75, 0.0183),
            0px 1px 2px rgba(57, 57, 75, 0.01);
        border-radius: 0.5rem;
        border: none;

        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-16);
        line-height: 1rem;
    
        display: flex;
        align-items: center;

        color: var(--color-gray-800);

        ::placeholder {
            color:  var(--color-gray-500);

        }

        &:focus{
            border: 1px solid var(--color-purple-500);
        }
    `}

    ${props => props.variant === Variant.SEARCH && css`
        background: none;
        /* border: none !important; */
        border: 0;
        outline: none;
        width: 21rem;

        // color: var(--color-red-200);
        color: var(--color-white);

        &:focus {
            outline: none;
            border: none !important;
        }
        
        ::placeholder{
            color: var(--color-red-200);
        }
    `} 

    ${props => props.variant === Variant.SETTINGS && css`
        padding: var(--space-10) var(--space-14);
        border: 1px solid var(--color-gray-300);

        font-size: var(--font-14);
        line-height: 0.875rem;

        :disabled {

            background: var(--color-gray-100);
            color: var(--color-gray-500);
        }

        ::placeholder {
            color: var(--color-gray-400);
        }
    `}

    ${props => (props.isError && props.variant === Variant.SETTINGS) && css`
        background: var(--color-orange-100);
        border: 1px solid var(--color-orange-900);

    `}

    ${props => props.variant === Variant.FILTER && css`
        background: none;
        border: 0;
        outline: none;
        width: 21rem;

        color: var(--color-white);

        &:focus {
            outline: none;
            border: none !important;
        }
        
        ::placeholder{
            color: var(--color-gray-500);
        }
    `} 

    &:focus {
        /* outline: none !important; */
        /* border: none !important; */
    }
    
`;

export const ErrorMessage = styled.div`
    /* position: absolute; */
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: var(--font-14);
    line-height: 0.875rem;

    color: var(--color-orange-900);
    bottom: 0;
    margin-top: var(--space-12);

    /* padding-top: 20px; */

`;

const StyledIcon = styled(ErrorIcon)`
    margin-right: 0.75rem;
`;
