// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { FunctionComponent, useEffect, useState, ReactNode, ChangeEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import Search from '../Search/index';
import UserProfile from '../UserProfile/index';

import { ReactComponent as TopbarLogo } from '../../assets/svg/topbar/topbar-logo.svg';
import { ReactComponent as NotificationIcon } from '../../assets/svg/topbar/notification.svg';
import { ReactComponent as SearchIcon } from '../../assets/svg/topbar/search-icon.svg';

import { getSearchPlaceholder, getSearchValue, setSearch } from '../../store/searchStore';
import Button from '../Button';
import { NAV_OPTIONS } from '../../navigation/NavigationComponent/Navigation';
import Popover, { Placement } from '../Popover';
import Notifications, { Notif } from './Notifications';
import { getCanDisturb, getNotifications, setNotifications } from '../../store/notificationsStore';
import { forceLogout, getRefreshExpire, getRefreshToken, getToken, getTokenExpire, getUser, setRefreshExpire, setRefreshToken, setToken, setTokenExpire } from '../../store/authStore';
import { getNotificationsByUserId, getAllNotifications } from '../../services/NotificationsService';
import { getFilterReceiptsByLocation, setFilterReceiptsByLocation } from '../../store/receiptsStore';
import { axiosInstance } from '../../services/axios';
import { config } from '../../store';
import { validateInput } from '../../helpers/validators';

type Props = {
    pageTitle: ReactNode,
    [x: string]: any
}

interface Location {
    title: string,
}

const TopBar: FunctionComponent<Props> = props => {
    const dispatch = useDispatch();
    const notificationDisturb = useSelector(getCanDisturb);
    const notifications = useSelector(getNotifications);
    const user = useSelector(getUser);

    const locationSearchValue = useSelector(getFilterReceiptsByLocation);

    const locSearchValue = useSelector(getSearchValue);

    const placeholder = useSelector(getSearchPlaceholder);
    const searchVal = useSelector(getFilterReceiptsByLocation);
    const [searchValue, setSearchValue] = useState<string>('');
    const [clickedNotif, setClickedNotif] = useState<boolean>(true);
    const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>();
    const [title, setTitle] = useState('Overview');
    const [userNotifs, setUserNotifs] = useState<Notif[]>([]);

    // const [debouncedSearchValue, setDebouncedSearchValue] = useDebounceState(searchValue, 300);

    const location = useLocation<Location>();
    const hasNotifications = userNotifs.length > 0;
    const tokenLocal = useSelector(getToken);
    const tokenExpireLocal = useSelector(getTokenExpire);
    const refreshTokenLocal = useSelector(getRefreshToken);
    const refreshExpireLocal = useSelector(getRefreshExpire);
    const [expire, setExpire] = useState(tokenExpireLocal);

    const checkTokenExpire = async () => {
        let tokenResult = tokenLocal;
        const payload = {
            token: tokenLocal,
            refreshToken: refreshTokenLocal
        };
        const baseUrl = process.env.REACT_APP_API;
        await axios.post(`${baseUrl}/auth/refreshToken`, payload).then(item => {
            const { token, refreshToken, tokenExpire, refreshExpire } = item.data;
            dispatch(setToken(token));
            dispatch(setRefreshToken(refreshToken));
            dispatch(setTokenExpire(tokenExpire));
            dispatch(setRefreshExpire(refreshExpire));
            setExpire(tokenExpire);
            tokenResult = token;
        });
        return tokenResult;
    };

    useEffect(() => {
        const requestIntercept = axiosInstance.interceptors.request.use(
            (config:any) => {
                if (!config.headers.Authorization) {
                    config.headers.Authorization = `Bearer ${tokenLocal}`;
                }
                return config;
            }, error => Promise.reject(error)
        );

        const responseIntercept = axiosInstance.interceptors.response.use(
            response => response,
            async error => {
                const prevRequest = error?.config;
                if (moment(new Date()).format() > moment(expire).format() && location.pathname === '/users') {
                    return Promise.reject(error);
                }

                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    let itemResult = '';
                    await checkTokenExpire().then(newToken => {
                        prevRequest.headers.Authorization = `Bearer ${newToken}`;
                        itemResult = axiosInstance(prevRequest);
                    });
                    return itemResult;
                }
                return Promise.reject(error);
            }
        );
        
        return () => {
            axiosInstance.interceptors.request.eject(requestIntercept);
            axiosInstance.interceptors.response.eject(responseIntercept);
        };
    }, [location, tokenLocal]);

    useEffect(() => {
        const currentNav = NAV_OPTIONS.find(nav => nav.navLink === location.pathname)?.name;
        setTitle(currentNav || 'Overview');
    }, [location]);

    useEffect(() => {
        if (location.pathname === '/receipts') {
            if (debouncedSearchValue === '') {
                setSearchValue('');
                dispatch(setSearch(''));
            } else {
                setSearchValue('');
                dispatch(setSearch(debouncedSearchValue));
            }
        } else {
            setSearchValue('');
            dispatch(setSearch(''));
            setDebouncedSearchValue('');
        }
    }, [locationSearchValue, location]);

    useEffect(() => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
            if (!isEqual(debouncedSearchValue, searchValue) && searchValue !== '') {
                setDebouncedSearchValue(searchValue);
            }
        }, 300);
        // Cancel the timeout if value changes (also on delay change or unmount)
        return () => {
            clearTimeout(handler);
        };
    }, [searchValue, 300]);

    useEffect(() => {
        dispatch(setSearch(debouncedSearchValue));
    }, [debouncedSearchValue]);

    useEffect(() => {
        const reversedNotif = [...notifications].reverse();
        setUserNotifs(reversedNotif || []);
    }, [notifications]);

    const intervalMinutes = () => {
        const mins = 2;
        const secs = mins * 10 * 1000;
        return secs;
    };

    useEffect(() => {
        const notifInterval = setInterval(() => {
            handleGetNotifications();
        }, intervalMinutes());
        return () => { clearInterval(notifInterval); };
    }, []);

    const onChangeSearchValue = (e: any) => {
        const { value } = e.currentTarget;
        if (validateInput(value)) {
            if (value === '') {
                setSearchValue(value);
                setDebouncedSearchValue(value);
            } else {
                setSearchValue(value);
            }
        }
    };

    const NotifClicked = () => {
        setTimeout(() => {
            setClickedNotif(false);
        }, 3000);
    };

    function handleGetNotifications() {
        // Get Notifications for user
        if (notificationDisturb) {
            getNotificationsByUserId(user?.user_id)
                // getAllNotifications()
                .then(res => {
                    // TBD: Format result passed to set state
                    // setUserNotifs(res);
                    dispatch(setNotifications(res));
                })
                .catch(err => {
                    // setUserNotifs([]);
                    setNotifications([]);
                });
        }
    }

    return (
        <TopBarWrapper>
            <TopBarContainer>
                <div style={{ minWidth: '214px', height: '48px'}}></div>
                <div className="topbar-content">
                    <div className='page-title'>
                        {title}
                    </div>

                    <div className='content'>
                        <div className="search-notifs">
                            {location.pathname === '/receipts' ||
                                location.pathname === '/locations' ||
                                location.pathname === '/users' ||
                                location.pathname === '/audit' ?
                                <Search
                                    placeholder={placeholder}
                                    value={searchValue}
                                    onChange={e => onChangeSearchValue(e)}
                                /> : <div className='search-container' />
                            }

                            <Popover
                                placement={Placement.bottom}
                                customContainerStyles={{ padding: 0, marginTop: '1.25rem', zIndex: 4 }}
                                isArrow
                                trigger={
                                    <NotificationBellContainer>
                                        {hasNotifications ?
                                            <div style={{ position: 'relative' }}>
                                                <Notification hasNotifications={true} onClick={() => NotifClicked()} />
                                                <NotificationIndicator>
                                                    <Indicator ind={clickedNotif} />
                                                </NotificationIndicator>
                                            </div> :
                                            <>
                                                <Notification hasNotifications={false} />
                                                <p>  </p>
                                            </>

                                        }

                                    </NotificationBellContainer>
                                }
                            >
                                <Notifications
                                    notifications={userNotifs}
                                    reloadNotifs={handleGetNotifications}
                                />
                            </Popover>
                        </div>

                        <div className="profile">
                            <UserProfile />
                        </div>

                    </div>
                </div>

            </TopBarContainer>
        </TopBarWrapper>
    );
};

export default TopBar;

const TopBarWrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const TopBarContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin: 0 var(--space-48);

    .logo-container{
        width: 13rem;
        justify-content: center;
    }

    .topbar-content{
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        /* justify-content: space-between; */

        /* margin-left: var(--space-48); */
    }

    .page-title{
        /* flex: 1; */
        width: 5rem;
        margin-right: 15rem;
        margin-left: 2rem;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: bold;
        font-size: var(--font-32);
        line-height: 2rem;

        color: var(--color-white);

    }

    .content{
        display: flex;
        flex-direction: row;
        align-items: center;

        min-width: 44.625rem;
    }

    .search-notifs {
        flex: 1;
        display: flex;
        flex-direction: row;

        height: 2rem;

        border-right: 2px solid var(--darker-easy);
        margin-right: var(--space-32);
        padding-right: var(--space-32);
    }

    .search-container {
        width: 24rem;
        padding: 4px 16px;
    }

    .profile {
        width: 13.75rem;
        height: 100%;
    }

`;

interface StyledProps {
    hasNotifications?: boolean
    ind?: boolean
}

const NotificationBellContainer = styled.div`
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--dark-easy);

    :hover{
        background: var(--darker-easy);
    }
`;

const Notification = styled(NotificationIcon) <StyledProps>`
    ${props => props.hasNotifications && css`
        fill: var(--color-white)
    `}
`;

const NotificationIndicator = styled.div`
    width: 0.375rem;
    height: 0.375rem;

    position: absolute;
    border-radius: 50%;

    right: -3px;
    top: -5px;

    z-index: 1;
    background: var(--darker-easy);

    border: 3px solid #701010;
    /* box-shadow: 0px 0px 8px 1px #ECC94B; */

`;

const Indicator = styled.div<StyledProps>`
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
    background: ${props => (props.ind ? 'var(--light-yellow)' : 'transparent')};
    box-shadow: ${props => (props.ind ? '0px 0px 8px 1px #ECC94B' : '0px 0px 8px 1px transparent')};
`;
