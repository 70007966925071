import axios from 'axios';
import { handleRawResponse, handleError, handleResponse } from '../utils';
import endpoints from '../endpoints';
import { axiosInstance } from '../axios';

export function getUsers(params?: any) {
    const url = endpoints.users;
    return axiosInstance.get(url, { params })
        .then(handleResponse)
        .catch(handleError);
}

export function createUser(data: any) {
    const url = endpoints.register;
    return axiosInstance.post(url, data)
        .then(handleResponse)
        .catch(handleError);
}

export function getUserById(id: string) {
    const url = endpoints.userById(id);
    return axiosInstance.get(url)
        .then(handleResponse)
        .catch(handleError);
}

export function updateUserById(id: string, data: any) {
    const url = endpoints.userById(id);
    return axiosInstance.put(url, data)
        .then(handleResponse)
        .catch(handleError);
}

export function removeUserById(id: string) {
    const url = endpoints.userById(id);
    return axiosInstance.delete(url)
        .then(handleResponse)
        .catch(handleError);
}

export function promoteUserById(id: string) {
    const url = endpoints.promoterUser(id);
    return axiosInstance.put(url)
        .then(handleResponse)
        .catch(handleError);
}

export function relocateUserById(id: string) {
    const url = endpoints.relocateUser(id);
    return axiosInstance.put(url)
        .then(handleResponse)
        .catch(handleError);
}
